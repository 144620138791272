import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PreviewsImage from '../Brand/PreviewsImage';
import { FetchApi } from 'context/FetchApi';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';
const UpdateProducts = () => {
  let { id } = useParams()
  const [value, setValue] = useState(0);
  let { suppliersAjex, fetchSuppliersAjex, fetchProductsAjex, productsAjex, categoriesAjex, fetchCategoriesAjex, fetchSubCategoriesAjex, subCategoriesAjex, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)

  let { isLang, selectedUpload, Profile } = useContext(LocalizationContext);

  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/products/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const [dataGallery, setDataGallery] = useState([]);
  const toast = useRef(null);
  let navigate = useNavigate()
  const DiscountType = [
    { name: 'Value', value: 'VALUE' },
    { name: 'Precent', value: 'PERCENT' },
  ];
  const items = [
    { label: <Link to={'/products'}>{t('products')}</Link> },
    { label: <Link to={`/products/edit/${id}`} className='p-2 border-round'>{t('products_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/edit/page/${id}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    })
    if (data?.Success === true) {
      setData(data?.Response);
      setDataGallery(data?.Response?.BrandProductGallery);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);

  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductOfferPrice: Data?.BrandProductOfferPrice || '',
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      BrandProductSerialCode: Data?.BrandProductSerialCode || '',
      IDBrandProduct: id || '',
      BrandProductLogo: selectedImage,
      BrandProductGallery: selectedUpload,
      IDSupplier: Data?.IDSupplier || '',
      IDBrand: Data?.IDBrand || '',
      BrandProductDiscountType: Data?.BrandProductDiscountType || '',
      BrandProductDiscountType: Data?.BrandProductDiscountType || '',
      SupplierShareType: Data?.SupplierShareType || '',
      SupplierShare: Data?.SupplierShare || '',
    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, {
          IDBrandProduct: values?.IDBrandProduct,
          IDSubCategory: values?.IDSubCategory,
          BrandProductTitleEn: values?.BrandProductTitleEn,
          BrandProductTitleAr: values?.BrandProductTitleAr,
          BrandProductDescEn: values?.BrandProductDescEn,
          BrandProductDescAr: values?.BrandProductDescAr,
          BrandProductOfferPrice: values?.BrandProductOfferPrice,
          BrandProductPrice: values?.BrandProductPrice,
          BrandProductSerialCode: values?.BrandProductSerialCode,
          BrandProductLogo: values?.BrandProductLogo,
          BrandProductGallery: selectedUpload,
          IDSupplier: values?.IDSupplier,
          SupplierShareType: values?.SupplierShareType,
          SupplierShare: values?.SupplierShare,
          IDBrand: values?.IDBrand,


        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });
  useEffect(() => {
    formik.setFieldValue('BrandProductLogo', selectedImage);
  }, [selectedImage]);



  useEffect(() => {
    formik.setValues({
      BrandProductTitleEn: Data?.BrandProductTitleEn || '',
      BrandProductTitleAr: Data?.BrandProductTitleAr || '',
      BrandProductPrice: Data?.BrandProductPrice || '',
      BrandProductOfferPrice: Data?.BrandProductOfferPrice || '',
      BrandProductDescEn: Data?.BrandProductDescEn || '',
      BrandProductDescAr: Data?.BrandProductDescAr || '',
      BrandProductSerialCode: Data?.BrandProductSerialCode || '',
      SupplierShareType: Data?.SupplierShareType || '',
      SupplierShare: Data?.SupplierShare || '',
      IDSupplier: Data?.IDSupplier || '', 
      IDBrandProduct: id || '',
    });

    fetchSuppliersAjex()
    if (Data?.BrandProductStatus === 'PENDING') {
      fetchBrandsAjex()
      fetchCategoriesAjex()
      fetchSubCategoriesAjex(Data.IDCategory)
      if (!Profile?.IDSupplier) {
        formik.setFieldValue("IDSupplier", Data?.IDSupplier)
      }

      formik.setFieldValue("IDBrand", Data?.IDBrand)
      formik.setFieldValue("IDCategory", Data?.IDCategory)
      formik.setFieldValue("IDSubCategory", Data?.IDSubCategory)
    }

  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <PreviewsImage gallery={dataGallery} fetchData={fetchData} />

      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid  mt-5 flex justify-content-center ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
            <div className=" shadow-6 mt-3      flex justify-content-center align-items-center   " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
              <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                {formik.values.BrandProductLogo ? (
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(formik.values.BrandProductLogo)}
                    alt={formik.values.BrandProductLogo.name}
                    width='300px'
                    height='250px'
                    className=' mx-auto '
                    style={{ objectFit: 'contain' }}
                  />
                ) :
                  <>
                    <img
                      loading="lazy"
                      src={Data.BrandProductLogo}
                      alt={'default-image'}
                      className=' rounded-circle mx-auto '
                      style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                    />
                  </>
                }

              </div>
              <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />

                <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                  <i className='pi pi-image color-wight'></i>
                </label>
              </div>
            </div>
            <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? "Main Image" : "الصورة الرئيسية"}</label>

          </div>

        </div>

        <div className="grid  mt-5  ">

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductSerialCode" className="font-bold block mb-2"> {t('BrandProductSerialCode')}    </label>
            <InputText
              name='BrandProductSerialCode'
              id="BrandProductSerialCode"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full p-inputtext-sm"
              value={formik.values.BrandProductSerialCode}
            />

          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
            <label htmlFor="IDSupplier" className="font-bold block mb-2"> {t('Supplier')}</label>
            <Dropdown filter
              options={suppliersAjex?.map(item => ({
                name: item.SupplierName,
                value: item.IDSupplier,
              }))}
              id="IDSupplier"
              name="IDSupplier"
              optionLabel="name"
              optionValue="value"
              value={formik.values.IDSupplier} // Add this line 
              onChange={(e) => {
                formik.setFieldValue("IDSupplier", e.value)
              }}

              onBlur={formik.handleBlur}
              placeholder={t('brand_placeholder')}
              className="w-full p-inputtext-sm"
              disabled={Profile?.IDSupplier}
            />
          </div>
          {
            Data?.BrandProductStatus === 'PENDING' && <>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDBrand" className="font-bold block mb-2"> {isLang === "en" ? 'Brand' : 'العلامة التجارية'}</label>
                <Dropdown filter
                  options={brandsAjex?.map(item => ({
                    name: item.BrandName,
                    value: item.IDBrand,
                  }))}
                  id="IDBrand"
                  name="IDBrand"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDBrand} // Add this line 
                  onChange={(e) => {
                    formik.setFieldValue("IDBrand", e.value)

                  }}

                  onBlur={formik.handleBlur}
                  placeholder={t('brand_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('categories_title')}</label>
                <Dropdown filter
                  options={categoriesAjex?.map(item => ({
                    name: item.CategoryName,
                    value: item.IDCategory,
                  }))}
                  id="IDCategory"
                  name="IDCategory"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDCategory} // Add this line 
                  onChange={(e) => {
                    formik.setFieldValue("IDCategory", e.value)
                    fetchSubCategoriesAjex(e.value)
                  }}

                  onBlur={formik.handleBlur}
                  placeholder={t('Category_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2">
                <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('subcategories_title')}</label>
                <Dropdown filter
                  options={subCategoriesAjex?.map(item => ({
                    name: item.SubCategoryName,
                    value: item.IDSubCategory,
                  }))}
                  id="IDSubCategory"
                  name="IDSubCategory"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDSubCategory} // Add this line 
                  onChange={(e) => {
                    formik.setFieldValue("IDSubCategory", e.value)
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t('SubCategory_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
            </>
          }
          <div className="lg:col-12 md:col-12 sm:col-12 mt-2 input__Col "></div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2"> {t('label_categories_nameEn')}    </label>
            <InputText
              name='BrandProductTitleEn'
              id="BrandProductTitleEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.BrandProductTitleEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">  {t('label_categories_nameAr')}</label>
            <InputText
              value={formik.values.BrandProductTitleAr}
              name='BrandProductTitleAr'
              id="BrandProductTitleAr" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
            <InputText
              value={formik.values.BrandProductPrice}
              name='BrandProductPrice'
              id="BrandProductPrice" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductOfferPrice" className="font-bold block mb-2">{t('discount')}</label>
            <InputText
              value={formik.values.BrandProductOfferPrice}
              name='BrandProductOfferPrice'
              id="BrandProductOfferPrice" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>



          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="SupplierShareType" className="font-bold block mb-2"> {t('SupplierShareType')} </label>
            <Dropdown filter
              id="SupplierShareType"
              name="SupplierShareType"
              value={formik.values.SupplierShareType}
              optionLabel="name"
              onChange={(e) => formik.setFieldValue("SupplierShareType", e.value)}
              onBlur={formik.handleBlur}
              options={DiscountType}
              placeholder={t('Discounttype')}
              className="w-full p-inputtext-sm"


            />

          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="SupplierShare" className="font-bold block mb-2">{t('SupplierShare')}</label>
            {
              formik.values.SupplierShareType === "VALUE" ?
                <InputNumber
                  value={value ? value : formik?.values?.SupplierShare}
                  name='SupplierShare'
                  onChange={(e) => {
                    formik.setFieldValue('SupplierShare', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="SupplierShare"
                  className="w-full  p-inputtext-sm"
                />
                :
                <InputNumber
                  value={value ? value : formik?.values?.SupplierShare}
                  name='SupplierShare'
                  onChange={(e) => {
                    formik.setFieldValue('SupplierShare', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="SupplierShare"
                  className="w-full  p-inputtext-sm"
                  prefix="%" />
            }


          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              value={formik.values.BrandProductDescEn}
              name='BrandProductDescEn'
              id="BrandProductDescEn" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              value={formik.values.BrandProductDescAr}
              name='BrandProductDescAr'
              id="BrandProductDescAr" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="danger" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/products'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateProducts
