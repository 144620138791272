
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Tooltip } from 'primereact/tooltip';

const ProductAttributes = () => {
  let { id, idValue } = useParams()
  let { t } = useTranslation()
  let { isLang } = useContext(LocalizationContext);
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const items = [
    { label: <Link className='opacity-70' to={'/products'}>{t('products')}   </Link> },
    { label: <Link to={`/products/attributes/${id}`} className='p-2 border-round'>  {t('attributes')}  </Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };

  // Add new suppliers
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to={`/products/attributes/${id}/add`}>
          <Button severity="danger" raised label={isLang === "en" ? "Add " : 'إضافة  '} icon="pi pi-plus" size='small' />
        </Link>

      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        <Tooltip target=".disabled-button" autoHide={false} />

        <Link to={`/products/attributes/${id}/edit/${rowData.IDProductAttribute}`}>
          <Button tooltip={t('brand_edit')} tooltipOptions={{ position: t('tooltip') }} icon="pi pi-pencil" rounded outlined text raised className="mr-2" />
        </Link>

        <Link onClick={e => {
          localStorage.setItem('ProductAttributeType', rowData.ProductAttributeType)
        }} to={`/products/attributes/${id}/values/${rowData.IDProductAttribute}`}>
          <Button tooltip={t('values')} tooltipOptions={{ position: t('tooltip') }} icon="pi pi-eraser" severity='danger' rounded outlined text raised className="mr-2" />
        </Link>
      </div>
    );
  };
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0"> </h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
      </span>
    </div>
  );

  const fetchProfileData = async () => {
    const url = `${process.env.REACT_APP_API_URL}/brands/products/attributes`;
    let data = await axios.post(url, { IDBrandProduct: id },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response);
  }
  useEffect(() => {
    fetchProfileData();
  }, [isLang, id])


  const statusOptions = [
    { label: t('active'), value: 1 },
    { label: t('inactive'), value: 0 },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/brands/products/attributes/status`, {
      IDProductAttribute: rowData?.IDProductAttribute,
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchProfileData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };

  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.ProductAttributeActive === 1 ? t('active') : rowData?.ProductAttributeActive === 0 ? t('inactive') : t('pending')

    return editingRow === rowData?.IDProductAttribute ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.ProductAttributeActive)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${rowData?.ProductAttributeActive === 1 ? t('active') : rowData?.ProductAttributeActive === 0 ? t('inactive') : t('pending')}`}

        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        severity={getSeverity(rowData.ProductAttributeActive)}
        onClick={() => setEditingRow(rowData?.IDProductAttribute)}
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span> {editingStatus}</span>
          <i className='pi pi-angle-down'></i>
        </div>
      </Tag>
    );
  };

  const getSeverity = (status) => {
    switch (status) {
      case 0:
        return 'danger';

      case 1:
        return 'success';

      case 'PENDING':
        return 'warning';

      case '':
        return 'info';
    }
  };
  const handeText = (text) => {
    return text?.ProductAttributeType?.replace(/_/g, ' ').charAt(0).toUpperCase() + text?.ProductAttributeType?.replace(/_/g, ' ').slice(1)?.toLowerCase()
  }

  return (
    <div className='mb-8 '>
      <BreadCrumb model={items} home={home} />
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>
        <DataTable className="custom-datatable"
          showGridlines
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          header={header}>
          <Column field="ProductAttribute" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="ProductAttributeType" body={handeText} header={t('type')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column field="ProductAttributeActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
        </DataTable>

      </div>

    </div>
  );
};


export default ProductAttributes
