import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Image } from 'primereact/image';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import img from './../../../constants/Img';
import AddGallery from './AddGallery';

const BrandGallery = ({ modalDone, setModalDone, id }) => {
    let { isLang } = useContext(LocalizationContext);
    const [Data, setData] = useState(null);
    const dt = useRef(null);
    let { t } = useTranslation()
    const [modalOpen, setModalOpen] = useState(false);
    const toast = useRef(null);

    // Add new brands
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Button raised onClick={() => setModalOpen(true)} label={t('brand_add')} severity="danger" icon="pi pi-plus" size='small' />
            </div>
        );
    };
    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/brands/gallery`;
        let data = await axios.post(url, { IDBrand: id },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }
    useEffect(() => {
        if (modalDone) {
            fetchData();
        }
    }, [isLang, id])

    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            {
                rowData.BrandGalleryPath ?
                    <Image
                        preview
                        loading="lazy"
                        src={rowData.BrandGalleryPath}
                        alt={'brand iamge'}
                        width='90'
                        height='100%'
                        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
                    /> :
                    <img src={img.DefaultImage} alt="" srcset="" />
            }
        </div>
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button onClick={() => DeleteGalleryData(rowData?.IDBrandGallery)} icon="pi pi-trash" severity="danger"  rounded outlined text raised className="mr-2" />
            </div>
        );
    };


    const DeleteGalleryData = async (id) => {
        const url = `${process.env.REACT_APP_API_URL}/brands/gallery/remove/${id}`;

        try {

            let { data } = await axios.get(url,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                    },
                })

            if (data?.Success) {
                fetchData()
                toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
            }

        } catch ({ response }) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });

        }
    }
    return (
        <>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalDone}
                style={{ width: '50rem' }}
                modal
                onHide={setModalDone}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                header={t('gallery')}
            >
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    rowHover
                    showGridlines >
                    <Column field="BrandGalleryPath" header={t('slider')} body={imageBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

                </DataTable>
            </Dialog>
            <AddGallery
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                IDBrand={id}
                fetchData={fetchData}
            />
        </>
    )
}

export default BrandGallery