
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentMethods = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
 
    const [Data, setData] = useState(null);
 

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchProfileData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/paymentmethods`;
        let data = await axios.get(url, 
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response); 
 

    }
    useEffect(() => {
        fetchProfileData();
    }, [isLang, page])

 
 
 
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 1 },
        { label: t('inactive'), value: 0},
   
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.get(`${process.env.REACT_APP_API_URL}/paymentmethods/status/${rowData?.IDPaymentMethod}`,    {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchProfileData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.PaymentMethodActive == 1 ? t('active') : t('inactive');
        const placeholderText = rowData?.PaymentMethodActive !== 1 ? t('active') : t('inactive');

        return editingRow === rowData?.IDPaymentMethod ? (
            <Dropdown  
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.PaymentMethodActive)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${placeholderText}`}

                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded   
                severity={getSeverity(rowData.PaymentMethodActive)}
                onClick={() => setEditingRow(rowData?.IDPaymentMethod)}
                >

                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    <i className='pi pi-angle-down'></i>

                </div>

            </Tag>
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 0:
                return 'danger';

            case 1:
                return 'success';

            case 'PENDING':
                return 'warning';

            case '':
                return 'secondary';
        }
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <Image
                preview
                loading="lazy"
                src={rowData.PaymentMethodLogo}
                alt={'brnad iamge'}
                width='90'
                height='100%'
                className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
            />
        </div>
    };
 
 
    return (
        <div className='mb-8 '>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable
                    className='custom-datatable'
                    scrollable
                    header={header}
                    scrollHeight='550px'
                    value={Data}
                    dataKey='id'
                    showGridlines
                    globalFilter={globalFilter} 
                >
                    <Column field="PaymentMethodLogo" header={t('Client_Picture')} body={imageBodyTemplate}></Column>
                    <Column  field="PaymentMethodName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column  field="PaymentMethodActive" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                </DataTable>
 
            </div>
 
        </div>
    );
};


export default PaymentMethods
