import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

const AddSuppliers = () => {

  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/suppliers/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link to={'/suppliers'}>{t('SupplierName')}   </Link> },
    { label: <Link to={'/suppliers/add'} className='p-2 border-round'>  {t('branch_add')}  </Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      SupplierNameEn: '',
      SupplierNameAr: '',
      SupplierNotes: '',
      SupplierContactName: '',
      SupplierContactPhone: '+20',
      SupplierSecondPhone: '+20',
      SupplierPhone: '+20',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/suppliers')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  return (
    <div>
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
        <BreadCrumb model={items} home={home} />
        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="SupplierNameEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
              <InputText
                name='SupplierNameEn'
                id="SupplierNameEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierNameAr" className="font-bold block mb-2">{t('label_categories_nameAr')}</label>
              <InputText
                name='SupplierNameAr'
                id="SupplierNameAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierPhone" className="font-bold block mb-2">{t('phone')}</label>
              <InputText 

                name='SupplierPhone'
                id="SupplierPhone"
                type='text'
                className="w-full  p-inputtext-sm" 
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.SupplierPhone}
              />
            </div>


            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierSecondPhone" className="font-bold block mb-2">{t('Second_Phone')}</label>
              <InputText
                name='SupplierSecondPhone'
                id="SupplierSecondPhone"
                type='text'
                className="w-full  p-inputtext-sm" 
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.SupplierSecondPhone}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierContactName" className="font-bold block mb-2">{t('BrandContactName')}</label>
              <InputText
                name='SupplierContactName'
                id="SupplierContactName"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierContactPhone" className="font-bold block mb-2">{t('BrandContactPhone')}</label>
              <InputText 

                name='SupplierContactPhone'
                id="SupplierContactPhone"
                type='text'
                className="w-full  p-inputtext-sm" 
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.SupplierContactPhone}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="SupplierNotes" className="font-bold block mb-2">{t('Note')}</label>
              <InputTextarea
                name='SupplierNotes'
                id="SupplierNotes"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={'/suppliers'}>
              <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AddSuppliers