import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone';


function Previews({ files, removeImage, gallery, removeImageFromApi }) {
    const thumbsContainer = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    };

    const thumb = {
        position: 'relative',
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        width: 100,
        height: 100,
        padding: 4,
        boxSizing: 'border-box',
    };

    const thumbInner = {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
    };

    const img = {
        display: 'block',
        width: 'auto',
        height: '100%',
    };

    const removeButton = {
        position: 'absolute',
        top: 0,
        right: 0,
        background: '#fff',
        padding: '2px',
        cursor: 'pointer',
        zIndex: 1,
    };

    useEffect(() => {
        return () => {
            // Cleanup object URLs when component unmounts or files change
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        };
    }, [files]);

    return (
        <section className="container">
            <aside style={thumbsContainer}>
                {
                    gallery?.map((file, index) => (
                        <div style={thumb} key={file.preview}>
                            <button
                                style={removeButton}
                                onClick={() => removeImageFromApi(file?.IDBrandProductGallery)}
                                title="Remove"
                                className='remove_image'
                            >
                                <i className="pi pi-trash"></i>
                            </button>
                            <div style={thumbInner}>
                                <img
                                    src={`${file.BrandProductGalleryPath}`}
                                    style={img}
                                    alt={`Preview ${index}`}
                                    onLoad={() => {
                                        // Revoke data uri after image is loaded
                                        URL.revokeObjectURL(file.preview);
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                {files.map((file, index) => (
                    <div style={thumb} key={file.preview}>
                        <button
                            style={removeButton}
                            onClick={() => removeImage(index)}
                            title="Remove"
                            className='remove_image'
                        >
                            <i className="pi pi-trash"></i>
                        </button>
                        <div style={thumbInner}>
                            <img
                                src={file.preview}
                                style={img}
                                alt={`Preview ${index}`}
                                onLoad={() => {
                                    // Revoke data uri after image is loaded
                                    URL.revokeObjectURL(file.preview);
                                }}
                            />
                        </div>
                    </div>
                ))}
            </aside>
        </section>
    );
}

const PreviewsImage = ({ gallery, fetchData ,type }) => {
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);

    let { selectedUpload, setUploadImage } = useContext(LocalizationContext);
    const [selectedImages, setSelectedImages] = useState([]);


    const onDrop = (acceptedFiles) => {
        const newImages = acceptedFiles.map((file) => ({
            preview: URL.createObjectURL(file),
            file: file,
        }));
        setSelectedImages((prevImages) => [...prevImages, ...newImages]);
        setUploadImage((prevUpload) => [...prevUpload, ...acceptedFiles]);
    };

    const removeImage = (indexToRemove) => {
        setSelectedImages((prevImages) =>
            prevImages.filter((_, index) => index !== indexToRemove)
        );
        setUploadImage((prevUpload) =>
            prevUpload.filter((_, index) => index !== indexToRemove)
        );
     
    };

    const removeImageFromApi = async (id) => {
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/${type}/gallery/remove/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
        })
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop,
        multiple: true,
    });

    const url = `${process.env.REACT_APP_API_URL}/brands/products/add`;



    return (
        <>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <div
                {...getRootProps()}
                style={{
                    border: '2px dashed #cccccc',
                    padding: '20px',
                    textAlign: 'center',
                    margin: '20px 0',
                    borderRadius: '10px'

                }}
                className=' cursor-pointer'
            >
                <input {...getInputProps()} />
                <p className='opacity-70 cursor-pointer'>Drag drop some images here, or click to select images</p>
            </div>
            <Previews
                files={selectedImages}
                gallery={gallery}
                removeImage={removeImage}
                removeImageFromApi={removeImageFromApi} />
        </>
    );
};

export default PreviewsImage
