
import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
const Clients = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [IdDeleteusers, setIdDeleteusers] = useState(null);
    const [Data, setData] = useState(null);
    const [deleteLoadind, setdeleteLoadind] = useState(false);
    const [summary, setSummary] = useState(null);

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchProfileData = async () => {
        
        const url = `${process.env.REACT_APP_API_URL}/clients`;
        let data = await axios.post(url, { IDPage: page + 1 },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Clients);
        setPagesNum(data?.data?.Response?.Pages)
        setSummary(data?.data?.Response)

    }
    useEffect(() => {
        fetchProfileData();
    }, [isLang, page])



    // delete users
    const confirmDeleteusers = async (users) => {
        setDeleteusersDialog(true);
        setIdDeleteusers(users?.IDClient)
    };
    const deleteusers = async (rowData) => {
        setdeleteLoadind(true)
        await axios.post(`${process.env.REACT_APP_API_URL}/users/status`, {
            IDClient: IdDeleteusers,
            ClientStatus: 'DELETED'
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });

                setTimeout(() => {
                    setDeleteusersDialog(false);
                    fetchProfileData()
                    setdeleteLoadind(false)
                }, 1000);
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)
        })

    };
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" outlined onClick={hideDeleteusersDialog} />
            <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
        </div>
    );
    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('NOT_VERIFIED'), value: 'NOT_VERIFIED' },
        { label: t('BLOCKED'), value: 'BLOCKED' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/clients/status`, {
            IDClient: rowData?.IDClient,
            ClientStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchProfileData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.ClientStatus === 'ACTIVE' ? t('active') : rowData?.ClientStatus === 'NOT_VERIFIED' ? t('NOT_VERIFIED') : rowData?.ClientStatus === 'BLOCKED' ? t('BLOCKED') : t('pending')

        return editingRow === rowData?.IDClient ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.ClientStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.ClientStatus === 'ACTIVE' ? t('active') : rowData?.ClientStatus === 'NOT_VERIFIED' ? t('NOT_VERIFIED') : t('pending')}`}

                className="p-inputtext-sm "
            />
        ) : (
            <Tag
                rounded 
                severity={getSeverity(rowData.ClientStatus)}
                onClick={() => setEditingRow(rowData?.IDClient)}
            >

                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    <i className='pi pi-angle-down'></i>

                </div>

            </Tag>
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'NOT_VERIFIED':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';

            case 'BLOCKED':
                return 'secondary';
        }
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            <Image
                preview
                loading="lazy"
                src={rowData.ClientPicture}
                alt={'client image'}
                width='90'
                height='100%'
                className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
            />
        </div>
    };
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.RegisterDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const navigate = useNavigate();

    const handleRowSelect = (event) => {
        console.log(event.data?.IDClient);
        navigate(`/clients/details/${event.data?.IDClient}`);

    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Link to={`/clients/details/${rowData?.IDClient}`}>
                    <Button icon="pi pi-eye" severity="danger" rounded outlined text raised className="mr-2" />
                </Link>
            </div>
        );
    };
    return (
        <div className='mb-8 '>
            {/* <SummaryClient data={summary} /> */}
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable
                    className='custom-datatable'
                    scrollable
                    header={header}
                    scrollHeight='550px'
                    value={Data}
                    dataKey='id'
                    showGridlines
                    globalFilter={globalFilter}
                    selectionMode='single' // or 'multiple' 
                >
                    <Column field="ClientPicture" header={t('Client_Picture')} body={imageBodyTemplate}></Column>
                    <Column field="ClientName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientEmail" header={t('email')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="ClientStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="RegisterDate" header={t('created_at')} body={date} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10} 
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Confirm Delete' : 'تأكيد الحذف'} modal footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? " Are you sure you want to delete   ?" : ' هل أنت متأكد أنك تريد حذف   ؟   '}
                    </span>
                </div>
            </Dialog>
        </div>
    );
};


export default Clients
