import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';

const UpdateSuppliers = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/suppliers/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()

  const items = [
    { label: <Link to={'/suppliers'}>{t('SupplierName')}</Link> },
    { label: <Link to={`/suppliers/edit/${id}`} className='p-2 border-round'>{t('brand_edit')}</Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/suppliers/edit/page/${id}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    })
    if (data?.Success === true) {
      setData(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' };
  const formik = useFormik({
    initialValues: {
      SupplierNameEn: Data?.SupplierNameEn || '',
      SupplierNameAr: Data?.SupplierNameAr || '',
      SupplierPhone: Data?.SupplierPhone || '',
      SupplierSecondPhone: Data?.SupplierSecondPhone || '',
      SupplierContactName: Data?.SupplierContactName || '',
      SupplierNotes: Data?.SupplierNotes || '',
      SupplierContactPhone: Data?.SupplierContactPhone || '',
      IDSupplier: id || '',


    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/suppliers')
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    formik.setValues({
      SupplierNameEn: Data?.SupplierNameEn || '',
      SupplierNameAr: Data?.SupplierNameAr || '',
      SupplierPhone: Data?.SupplierPhone || '+20',
      SupplierSecondPhone: Data?.SupplierSecondPhone || '+20',
      SupplierContactName: Data?.SupplierContactName || '',
      SupplierNotes: Data?.SupplierNotes || '',
      SupplierContactPhone: Data?.SupplierContactPhone || '+20',

      IDSupplier: id || '',
    });
  }, [Data, formik.setValues]);



  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />


      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="SupplierNameEn" className="font-bold block mb-2"> {t('label_categories_nameEn')}    </label>
            <InputText
              name='SupplierNameEn'
              id="SupplierNameEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.SupplierNameEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SupplierNameAr" className="font-bold block mb-2">  {t('label_categories_nameAr')}</label>
            <InputText
              value={formik.values.SupplierNameAr}
              name='SupplierNameAr'
              id="SupplierNameAr" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SupplierPhone" className="font-bold block mb-2">{t('phone')}</label>
            <InputText
              value={formik.values.SupplierPhone}
              name='SupplierPhone'
              id="SupplierPhone" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SupplierSecondPhone" className="font-bold block mb-2">{t('Second_Phone')}</label>
            <InputText
              value={formik.values.SupplierSecondPhone}
              name='SupplierSecondPhone'
              id="SupplierSecondPhone" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>


          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SupplierContactName" className="font-bold block mb-2">{t('BrandContactName')}</label>
            <InputText
              value={formik.values.SupplierContactName}
              name='SupplierContactName'
              id="SupplierContactName" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="SupplierContactPhone" className="font-bold block mb-2"> {t('BrandContactPhone')}    </label>
            <InputText
              name='SupplierContactPhone'
              id="SupplierContactPhone"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.SupplierContactPhone}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="SupplierNotes" className="font-bold block mb-2">{t('Note')}</label>
            <InputTextarea
              value={formik.values.SupplierNotes}
              name='SupplierNotes'
              id="SupplierNotes" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>

        </div>



        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="danger" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/suppliers'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}
export default UpdateSuppliers