import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

const AddPromoCodeProducts = ({ modalOpen, setModalOpen, IDPromoCode, fetchData }) => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const toast = useRef(null);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    let { productsAjex, fetchProductsAjex } = useContext(FetchApi)

    const setrewardpointsDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button severity='danger' label={t('cancel')} outlined icon="pi pi-times" onClick={() => setModalOpen(false)} size='small' />
            <Button severity='success' label={t('save')} loading={loading} raised icon="pi pi-check" size='small' onClick={() => AddBrandProduct()} />
        </div>
    );

    const AddBrandProduct = async (IDBrandProduct) => {
        const url = `${process.env.REACT_APP_API_URL}/promos/products/add`;

        try {
            setLoading(true);
            let { data } = await axios.post(url, {
                IDPromoCode,
                IDBrandProduct:value,
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                    },
                })

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setModalOpen(false)
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } else if (!data?.Success) {
                toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                setLoading(false);
            } else if (data?.status === 400) {
                toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                setLoading(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
            }

        } catch ({ response }) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchProductsAjex()
        if (modalOpen) {
        }


    }, [IDPromoCode])

    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalOpen}
                style={{ width: '25rem' }}
                modal
                onHide={setModalOpen}
                footer={setrewardpointsDialogFooter}
                header='  Add Promo Code Products'
                dir={isLang === "en" ? 'ltr' : 'rtl'}
            >
                <Dropdown filter
                    options={productsAjex?.map(item => ({
                        name: item.BrandProductTitle,
                        value: item.IDBrandProduct,
                    }))}
                    id="IDBrandProduct"
                    name="IDBrandProduct"
                    optionLabel="name"
                    optionValue="value"
                    value={value} // Add this line 
                    onChange={(e) => {
                        setValue(e.value)
                    }}
                    placeholder={t('Products_placeholder')}
                    className="w-full p-inputtext-sm"
                />
            </Dialog>
        </div>
    )
}

export default AddPromoCodeProducts