import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const UpdateProductAttributeValues = () => {
  let { id, idEdit, idValue } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const urlUpdateData = `${process.env.REACT_APP_API_URL}/brands/products/attributes/values/edit`;
  const [loading, setLoading] = useState(false);
  const [Data, setData] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()



  const items = [
    { label: <Link className='opacity-70' to={'/products'}>{t('products')}   </Link> },
    { label: <Link className='opacity-70' to={`/products/attributes/${id}`}  >  {t('attributes')}  </Link> },
    { label: <Link className='opacity-70' to={`/products/attributes/${id}/values/${idValue}`}  >  {t('values')}  </Link> },
    { label: <Link to={`/products/attributes/${id}/values/${idValue}/edit/${idEdit}`} className='p-2 border-round'>  {t('update')}  </Link> }
  ];
  const fetchData = async () => {
    let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/attributes/values/edit/page/${idEdit}`, {
      headers: {
        // 'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    })
    if (data?.Success === true) {
      setData(data?.Response);
    }
  }
  useEffect(() => {
    fetchData()
  }, []);
  const home = { icon: 'pi pi-home', url: '/' }
  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);
  const formik = useFormik({
    initialValues: {
      ProductAttributeValueEn: Data?.ProductAttributeValueEn || '',
      ProductAttributeValueAr: Data?.ProductAttributeValueAr || '',
      ProductAttributeValueAmount: Data?.ProductAttributeValueAmount || '',
      IDProductAttribute: idValue || '',
      IDProductAttributeValue: idEdit || '',
      ProductAttributeValueImage: selectedImage,


    },
    onSubmit: async (values, { resetForm }) => {

      try {
        setLoading(true);
        let { data } = await axios.post(urlUpdateData, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success === true) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/products/attributes/${id}/values/${idValue}`)
            resetForm();
          }, 1000);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          resetForm();
          setLoading(false);
        } else {
          setLoading(false);

          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.ApiMsg, life: 3000 });
        setLoading(false);

      }
    }
  });


  useEffect(() => {
    formik.setValues({
      ProductAttributeValueEn: Data?.ProductAttributeValueEn || '',
      ProductAttributeValueAr: Data?.ProductAttributeValueAr || '',
      ProductAttributeValueAmount: Data?.ProductAttributeValueAmount || '',
      IDProductAttribute: idValue || '',
      IDProductAttributeValue: idEdit || '',


    });
  }, [Data, formik.setValues]);
  useEffect(() => {
    formik.setFieldValue('ProductAttributeValueImage', selectedImage);
  }, [selectedImage]);


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        {
          (localStorage.getItem('Role') == 1 || localStorage.getItem('ProductAttributeType') == "PRODUCT") &&
          <div className="grid  mt-5  flex justify-content-center">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
              <div className=" shadow-6 mt-3      flex justify-content-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                  {formik.values.ProductAttributeValueImage ? (
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(formik.values.ProductAttributeValueImage)}
                      alt={formik.values.ProductAttributeValueImage.name}
                      width='300px'
                      height='250px'
                      className=' mx-auto '
                      style={{ objectFit: 'contain' }}
                    />
                  ) :
                    <>
                      <img
                        loading="lazy"
                        src={Data.ProductAttributeValueImage}
                        alt={'default-image'}
                        className=' rounded-circle mx-auto '
                        style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                      />
                    </>
                  }

                </div>
                <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    onChange={handleImageSelect}
                    style={{ display: 'none' }}
                  />

                  <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                    <i className='pi pi-image color-wight'></i>
                  </label>
                </div>
              </div>
              <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{t('image')}</label>

            </div>
          </div>
        }


        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="ProductAttributeValueEn" className="font-bold block mb-2"> {t('label_categories_nameEn')}    </label>
            <InputText
              name='ProductAttributeValueEn'
              id="ProductAttributeValueEn"
              type="text"
              className="w-full"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ProductAttributeValueEn}
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="ProductAttributeValueAr" className="font-bold block mb-2">  {t('label_categories_nameAr')}</label>
            <InputText
              value={formik.values.ProductAttributeValueAr}
              name='ProductAttributeValueAr'
              id="ProductAttributeValueAr" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
              onBlur={formik.handleBlur} />
          </div>
          {
            (localStorage.getItem('Role') == 1 || localStorage.getItem('ProductAttributeType') == "PRODUCT") &&
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="ProductAttributeValueAmount" className="font-bold block mb-2">  {t('amount')}</label>
              <InputText
                value={formik.values.ProductAttributeValueAmount}
                name='ProductAttributeValueAmount'
                id="ProductAttributeValueAmount" type="text" className="w-full  p-inputtext-sm" onChange={formik.handleChange}
                onBlur={formik.handleBlur} />
            </div>
          }

        </div>
        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button raised icon="pi pi-check" severity="danger" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={`/products/attributes/${id}/values/${idValue}`}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default UpdateProductAttributeValues