

import LogoSvg from "../../constants/LogoSvg";

const useRoutes = () => {


    const routes = [
        {
            path: "",
            nameEn: "Dashboard",
            nameAr: "لوحة القيادة",
            icon: <LogoSvg.Dashboard className="logoSvg" style={{ width: 17 }} />,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]
        },
        {
            path: "/orders",
            nameEn: "Orders",
            nameAr: "طلبات",
            icon: <i className="pi pi-shopping-cart"></i>, 
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]
        },
        {
            path: "/supplier-orders",
            nameEn: "Orders",
            nameAr: "طلبات",
            icon: <i className="pi pi-shopping-cart"></i>, 
            permission: [localStorage.getItem('Role') == 2 ? 1 : 0]


        },
        {
            path: "/suppliers-order",
            nameEn: "Suppliers Order",
            nameAr: "طلبات",
            icon: <i className="pi pi-shopping-cart"></i>,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]

        },
        {
            path: "/users",
            nameEn: "Users",
            nameAr: "مستخدمين",
            icon: <i className='pi pi-user'></i>,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]

        },
        {
            nameAr: "إعدادات",
            nameEn: "Configration ",
            icon: <i className='pi pi-cog'></i>,

            subRoutes: [
                {
                    path: "/categories",
                    nameAr: "التصنيفات",
                    nameEn: "Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                },
                {
                    path: "/subcategories",
                    nameAr: "التصنيفات الفرعية",
                    nameEn: "Sub Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                },

                {
                    path: "/general_settings",
                    nameAr: "الاعدادات العامة",
                    nameEn: "General settings",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },
                {
                    path: "/promo",
                    nameAr: "كود الخصم",
                    nameEn: "Promo Code  ",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },

                {
                    path: "/ads",
                    nameAr: "لافتات",
                    nameEn: "Banners",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },
                {
                    path: "/paymentmethods",
                    nameAr: "طرق الدفع",
                    nameEn: "Payment methods",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },
            ],
        },
        {
            nameAr: "المواقع    ",
            nameEn: "Locations ",
            icon: <i className='pi pi-map'></i>,

            subRoutes: [
                // {
                //     path: "/countries",
                //     nameAr: "بلدان",
                //     nameEn: "Countries",
                //     icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                // },
                {
                    path: "/cities",
                    nameAr: "المحافظات",
                    nameEn: "Governorates",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },
                {
                    path: "/areas",
                    nameAr: "المناطق",
                    nameEn: "Areas",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,

                },
            ],
        }, 
        {
            path: "/brands",
            nameEn: "Brands",
            nameAr: "العلامات التجارية",
            icon: <i className='pi pi-qrcode'></i>,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]


        },
        {
            path: "/suppliers",
            nameEn: "Suppliers",
            nameAr: "الموردين",
            icon: <i className='pi pi-users'></i>,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]



        },
        {
            path: "/products",
            nameEn: "Products",
            nameAr: "منتجات",
            icon: <i className='pi pi-box'></i>, 


        },
        {
            path: "/clients",
            nameEn: "Clients",
            nameAr: "العملاء",
            icon: <i className='pi pi-users'></i>,
            permission: [localStorage.getItem('Role') == 1 ? 1 : 0]


        },
        {
            nameEn: "Accounts",
            nameAr: "الحسابات",
            icon: <i className='pi pi-money-bill'></i>,
            subRoutes: [
                {
                    path: "/revenue",
                    nameEn: "ٌRevenue",
                    nameAr: "إيرادات ",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,
                },
                {
                    path: "/expenses",
                    nameEn: "Expenses",
                    nameAr: "مصروفات",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,


                },
                {
                    path: "/category_account",
                    nameEn: "Categories ",
                    nameAr: "فئات",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,


                },

                {
                    path: "/subcategory_account",
                    nameAr: "التصنيفات الفرعية",
                    nameEn: "Sub Categories",
                    icon: <LogoSvg.Dote className="logoSvg" style={{ width: 7 }} />,


                },
            ],
        },

    ];
    return {
        routes
    }
}


export default useRoutes;
