import axios from 'axios';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PreviewsImage from './PreviewsImage';

const AddBrand = () => {
  let { isLang, selectedUpload } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()


  const items = [
    { label: <Link to={'/brands'}>{t('brand_name')}   </Link> },
    { label: <Link to={'/brands/add'} className='p-2 border-round'>  {t('brand_add')}  </Link> }
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => {
    setSelectedImage(event.target.files[0]);
  };



  const home = { icon: 'pi pi-home', url: '/' };

  const formik = useFormik({
    initialValues: {
      BrandNameEn: '',
      BrandNameAr: '',
      BrandNumber: '',
      BrandEmail: '',
      BrandDescEn: '',
      BrandDescAr: '',
      BrandLogo: selectedImage,

    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          BrandNameEn: values?.BrandNameEn,
          BrandNameAr: values?.BrandNameAr,
          BrandNumber: values?.BrandNumber,
          BrandEmail: values?.BrandEmail,
          BrandLogo: selectedImage, 
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/brands')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('BrandLogo', selectedImage);
  }, [selectedImage]);

  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
    

      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
        <div className="grid  mt-5 flex justify-content-center a ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col flex justify-content-center align-items-center flex-column ">
            <div className=" shadow-4 mt-3  00 flex justify-content-center align-items-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
              <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                {formik.values.BrandLogo ? (
                  <Image
                    preview
                    loading="lazy"
                    src={URL.createObjectURL(formik.values.BrandLogo)}
                    alt={formik.values.BrandLogo.name}
                    width='300px'
                    height='250px'
                    className=' mx-auto '
                    style={{ objectFit: 'contain' }}
                  />
                ) :
                  <>
                    <img
                      loading="lazy"
                      src={img.DefaultImage}
                      alt={'default-image'}
                      className=' rounded-circle mx-auto '
                      style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                    />
                  </>
                }

              </div>
              <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                <input
                  type="file"
                  id="file-input"
                  accept="image/*"
                  onChange={handleImageSelect}
                  style={{ display: 'none' }}
                />

                <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                  <i className='pi pi-image color-wight'></i>
                </label>
              </div>
            </div>
            <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{t('logo')}</label>
          </div>
        </div>




        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="BrandNameEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
            <InputText
              name='BrandNameEn'
              id="BrandNameEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandNameAr" className="font-bold block mb-2">{t('label_categories_nameAr')}</label>
            <InputText
              name='BrandNameAr'
              id="BrandNameAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

              required

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandEmail" className="font-bold block mb-2">{t('email')}</label>
            <InputText
              name='BrandEmail'
              id="BrandEmail"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandNumber" className="font-bold block mb-2">{t('phone')}</label>
            <InputText
              name='BrandNumber'
              id="BrandNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}

              required

            />
          </div>
          {/* <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
            <InputTextarea
              name='BrandDescEn'
              id="BrandDescEn"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}


            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="BrandDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
            <InputTextarea
              name='BrandDescAr'
              id="BrandDescAr"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}


            />
          </div> */}

        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/brands'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}


export default AddBrand