import { LocalizationContext } from 'context/LangChange';
import { Dialog } from 'primereact/dialog'
import React, { useContext, useRef } from 'react'
import PreviewsImage from './PreviewsImage';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';

const AddGallery = ({ modalOpen, setModalOpen, IDBrand, fetchData }) => {
    let { isLang, selectedUpload } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const toast = useRef(null);
    let navigate = useNavigate()

    const [loading, setLoading] = useState(false);

    const setrewardpointsDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button severity='danger' label={t('cancel')} outlined icon="pi pi-times" onClick={() => setModalOpen(false)} size='small' />
            <Button severity='success' label={t('save')} loading={loading} raised icon="pi pi-check" size='small' onClick={() => AddGalleryData()} />
        </div>
    );

    const AddGalleryData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/brands/gallery/add`;

        try {
            setLoading(true);
            let { data } = await axios.post(url, {
                IDBrand,
                BrandPhotos: selectedUpload,
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                    },
                })

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setModalOpen(false)
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } else if (!data?.Success) {
                toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                setLoading(false);
            } else if (data?.status === 400) {
                toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
                setLoading(false);
            } else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
            }

        } catch ({ response }) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
            setLoading(false);
        }
    }
    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalOpen}
                style={{ width: '50rem' }}
                modal
                onHide={setModalOpen}
                footer={setrewardpointsDialogFooter}
                header='Add Gallery'
                dir={isLang === "en" ? 'ltr' : 'rtl'}
            >
                <PreviewsImage type={'brands'} />
            </Dialog>
        </div>
    )
}

export default AddGallery