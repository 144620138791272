import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Image } from 'primereact/image';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { format, parseISO } from 'date-fns';

const AddPromoCodeList = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/promos/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const [value, setValue] = useState(0);


  const items = [
    { label: <Link to={'/promo'}>{t('PromoCodes')}   </Link> },
    { label: <Link to={'/promo/add'} className='p-2 border-round'>  {t('add')}  </Link> }
  ];


  const home = { icon: 'pi pi-home', url: '/' };
  const PromoCodeType = [
    { name: t('value'), value: 'VALUE' },
    { name: t('PERCENT'), value: 'PERCENT' },
  ];
  const formik = useFormik({
    initialValues: {
      PromoCode: '',
      PromoCodeValue: '',
      PromoCodeType: 'VALUE',
      PromoCodeValueMax: '',
      PromoCodeTotalAmount: '',
      PromoCodeMaxNumber: '',
      PromoCodeUseNumber: '',
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/promo')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Invalid Request', life: 3000 });
          setLoading(false);

        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });


  return (
    <div className='mb-8'>
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

      <BreadCrumb model={items} home={home} />
      <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>

        <div className="grid  mt-5  ">
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PromoCode" className="font-bold block mb-2">{t('code')}</label>
            <InputText
              name='PromoCode'
              id="PromoCode"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required

            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PromoCodeValueMax" className="font-bold block mb-2">{t('ValueMax')}</label>
            <InputText
              name='PromoCodeValueMax'
              id="PromoCodeValueMax"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PromoCodeType" className="font-bold block mb-2"> {t('type')} </label>
            <Dropdown
              id="PromoCodeType"
              name="PromoCodeType"
              value={formik.values.PromoCodeType}
              optionLabel="name"
              onChange={(e) => formik.setFieldValue("PromoCodeType", e.value)}
              onBlur={formik.handleBlur}
              options={PromoCodeType}
              placeholder={t('Discounttype')}
              className="w-full p-inputtext-sm"
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
            <label htmlFor="PromoCodeValue" className="font-bold block mb-2">{t('value')}</label>
            {
              formik.values.PromoCodeType === "VALUE" ?
                <InputNumber
                  value={value}
                  name='PromoCodeValue'
                  onChange={(e) => {
                    formik.setFieldValue('PromoCodeValue', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="PromoCodeValue"
                  className="w-full  p-inputtext-sm"
                />
                :
                <InputNumber
                  value={value}
                  name='PromoCodeValue'
                  onChange={(e) => {
                    formik.setFieldValue('PromoCodeValue', e.value)
                  }}
                  onBlur={formik.handleBlur}
                  id="PromoCodeValue"
                  className="w-full  p-inputtext-sm"
                  prefix="%" />
            }


          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 xs:col-12 mt-2 input__Col  bg-info ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="PromoCodeStartDate" className="font-bold block mb-2">{isLang === "en" ? ' Start Date' : 'تاريخ بدء '}</label>
            <Calendar
              id="PromoCodeStartDate"
              name="PromoCodeStartDate"
              value={formik?.values?.PromoCodeStartDate ? parseISO(formik?.values?.PromoCodeStartDate) : null}
              placeholder={isLang === "en" ? ' start date' : ' تاريخ بدأ '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("PromoCodeStartDate", selectedDate);
                console.log(format(new Date(e.value), 'dd-MM-yyyy'));
              }}
              showIcon
              className='w-full'
              dateFormat="dd-mm-yy"
            />
          </div>
          <div className={`lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ${isLang === "en" ? 'Calendar_paymentEn' : 'Calendar_payment'}`}   >
            <label htmlFor="PromoCodeEndDate" className="font-bold block mb-2">{isLang === "en" ? ' End Date' : 'تاريخ نهاية '}</label>
            <Calendar
              id="PromoCodeEndDate"
              name="PromoCodeEndDate"
              value={formik?.values?.PromoCodeEndDate ? parseISO(formik?.values?.PromoCodeEndDate) : null}
              placeholder={isLang === "en" ? ' end date' : ' تاريخ نهاية '}
              onChange={(e) => {
                const selectedDate = e.value ? format(new Date(e.value), 'yyyy-MM-dd HH:mm:ss') : null;
                formik.setFieldValue("PromoCodeEndDate", selectedDate);
                console.log(format(new Date(e.value), 'dd-MM-yyyy'));
              }}
              showIcon
              className='w-full'
              dateFormat="dd-mm-yy"
              minDate={formik?.values?.PromoCodeStartDate ? new Date(formik?.values?.PromoCodeStartDate) : null}

            />

          </div>

          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PromoCodeTotalAmount" className="font-bold block mb-2">{t('MinimimOrder')}</label>
            <InputText
              name='PromoCodeTotalAmount'
              id="PromoCodeTotalAmount"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PromoCodeMaxNumber" className="font-bold block mb-2">{t('MaxNumber')}</label>
            <InputText
              name='PromoCodeMaxNumber'
              id="PromoCodeMaxNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
          <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
            <label htmlFor="PromoCodeUseNumber" className="font-bold block mb-2">{t('UseNumber')}</label>
            <InputText
              name='PromoCodeUseNumber'
              id="PromoCodeUseNumber"
              type='text'
              className="w-full  p-inputtext-sm"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
            />
          </div>
        </div>

        <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
          <div className="btn_submit">
            <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
          </div>
          <Link to={'/promo'}>
            <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
          </Link>
        </div>
      </form>
    </div>
  )
}

export default AddPromoCodeList