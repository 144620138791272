import Navber from 'Components/Navber/Navber';
import Error from 'Components/NotFound/Error';
import SidebarMenu from 'Components/Sidebar/SidebarMenu';
import SidebarSM from 'Components/Sidebar/SidebarSM';
import Sidebar from 'Components/Sidebar/Sildebar';
import Auth from 'Layout/Auth';
import BetterWay from 'Layout/BetterWay';
import AddCategoryAccount from 'Page/Admin/Account/Category/AddCategoryAccount';
import CategoryAccount from 'Page/Admin/Account/Category/CategoryAccount';
import UpdateCategoryAccount from 'Page/Admin/Account/Category/UpdateCategoryAccount';
import AddExpenses from 'Page/Admin/Account/Expenses/AddExpenses';
import Expenses from 'Page/Admin/Account/Expenses/Expenses';
import UpdateExpenses from 'Page/Admin/Account/Expenses/UpdateExpenses';
import AddSubCategoryAccount from 'Page/Admin/Account/SubCategory/AddSubCategoryAccount';
import SubCategoryAccount from 'Page/Admin/Account/SubCategory/SubCategoryAccount';
import UpdateSubCategoryAccount from 'Page/Admin/Account/SubCategory/UpdateSubCategoryAccount';
import AddAds from 'Page/Admin/Ads/AddAds';
import Ads from 'Page/Admin/Ads/Ads';
import UpdateAds from 'Page/Admin/Ads/UpdateAds';
import AddBrand from 'Page/Admin/Brand/AddBrand';
import Brand from 'Page/Admin/Brand/Brand';
import UpdateBrand from 'Page/Admin/Brand/UpdateBrand';
import Clients from 'Page/Admin/Clients/Clients';
import ClientsDetails from 'Page/Admin/Clients/ClientsDetails';
import AddAreas from 'Page/Admin/Locations/Areas/AddAreas';
import Areas from 'Page/Admin/Locations/Areas/Areas';
import UpdateAreas from 'Page/Admin/Locations/Areas/UpdateAreas';
import AddCities from 'Page/Admin/Locations/Cities/AddCities';
import Cities from 'Page/Admin/Locations/Cities/Cities';
import UpdateCities from 'Page/Admin/Locations/Cities/UpdateCities';
import AddCountries from 'Page/Admin/Locations/Countries/AddCountries';
import Countries from 'Page/Admin/Locations/Countries/Countries';
import UpdateCountries from 'Page/Admin/Locations/Countries/UpdateCountries';
import Order from 'Page/Admin/Order/Order';
import OrderDetails from 'Page/Admin/Order/OrderDetails';
import AddProducts from 'Page/Admin/Product/AddProducts';
import AddProductAttributeValues from 'Page/Admin/Product/ProductAttributeValues/AddProductAttributeValues';
import ProductAttributeValues from 'Page/Admin/Product/ProductAttributeValues/ProductAttributeValues';
import UpdateProductAttributeValues from 'Page/Admin/Product/ProductAttributeValues/UpdateProductAttributeValues';
import AddProductAttributes from 'Page/Admin/Product/ProductAttributes/AddProductAttributes';
import ProductAttributes from 'Page/Admin/Product/ProductAttributes/ProductAttributes';
import UpdateProductAttributes from 'Page/Admin/Product/ProductAttributes/UpdateProductAttributes';
import Products from 'Page/Admin/Product/Products';
import UpdateProducts from 'Page/Admin/Product/UpdateProducts';
import Profile from 'Page/Admin/Profile/Profile';
import AddPromoCodeList from 'Page/Admin/Promo/AddPromoCodeList';
import PromoCodeList from 'Page/Admin/Promo/PromoCodeList';
import PromoCodeProducts from 'Page/Admin/Promo/PromoCodeProducts';
import UpdatePromoCodeList from 'Page/Admin/Promo/UpdatePromoCodeList';
import AddUsers from 'Page/Admin/Users/AddUsers';
import UpdateUsers from 'Page/Admin/Users/UpdateUsers';
import UserData from 'Page/Admin/Users/UserData';
import AddCategory from 'Page/Admin/setting/Category/AddCategory';
import Category from 'Page/Admin/setting/Category/Category';
import UpdateCategory from 'Page/Admin/setting/Category/UpdateCategory';
import GeneralSettings from 'Page/Admin/setting/General/GeneralSettings';
import PaymentMethods from 'Page/Admin/setting/PaymentMethods';
import AddSubCategory from 'Page/Admin/setting/SubCategory/AddSubCategory';
import SubCategory from 'Page/Admin/setting/SubCategory/SubCategory';
import UpdateSubCategory from 'Page/Admin/setting/SubCategory/UpdateSubCategory';
import Dashboard from 'Page/Dashbord/Dashbord';
import AddSuppliers from 'Page/Suppliers/AddSuppliers';
import SupplierOrderList from 'Page/Suppliers/SupplierOrderList';
import Suppliers from 'Page/Suppliers/Suppliers';
import UpdateSuppliers from 'Page/Suppliers/UpdateSuppliers';
import Login from 'Page/auth/Login/Login';
import CompanyLedger from 'Page/Admin/Account/Revenue/CompanyLedger';
import AddCompanyLedger from 'Page/Admin/Account/Revenue/AddCompanyLedger';
import UpdateCompanyLedger from 'Page/Admin/Account/Revenue/UpdateCompanyLedger';
import SupplierLastOrder from './../Page/Suppliers/SupplierLastOrder';

const Component = {
    Auth,
    BetterWay,
    Navber,
    SidebarMenu,
    Sidebar,
    SidebarSM,
    Error,
    Dashboard,
    Auth,
    Login,
    // Users
    PaymentMethods,
    AddUsers,
    UpdateUsers,
    UserData,
    Profile,
    Category,
    AddCategory,
    UpdateCategory,
    SubCategory,
    AddSubCategory,
    UpdateSubCategory,
    GeneralSettings,
    Brand,
    AddBrand,
    UpdateBrand,
    UpdateProducts,
    Products,
    AddProducts,
    Clients,
    ClientsDetails,
    Order,
    OrderDetails,
    Ads,
    AddAds,
    UpdateAds,
    AddCities,
    Cities,
    UpdateCities,
    AddCountries,
    Countries,
    UpdateCountries,
    AddAreas,
    Areas,
    UpdateAreas,
    Suppliers,
    AddSuppliers,
    UpdateSuppliers,
    ProductAttributeValues,
    AddProductAttributeValues,
    UpdateProductAttributeValues,
    UpdateProductAttributes,
    ProductAttributes,
    AddProductAttributes,
    CompanyLedger,
    AddCompanyLedger,
    UpdateCompanyLedger,
    CategoryAccount,
    AddCategoryAccount,
    UpdateCategoryAccount,
    Expenses,
    AddExpenses,
    UpdateExpenses,
    SubCategoryAccount,
    AddSubCategoryAccount,
    UpdateSubCategoryAccount,
    Expenses,
    AddExpenses,
    UpdateExpenses,
    PromoCodeList,
    AddPromoCodeList,
    UpdatePromoCodeList,
    PromoCodeProducts,
    SupplierOrderList,
    SupplierLastOrder
}

export default Component




