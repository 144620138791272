import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import './Sidebar.scss';

// const menuAnimation = {
//   hidden: {
//     opacity: 0,
//     scaleY: 0,
//     transformOrigin: 'top',
//     transition: { duration: 0.5, ease: 'linear' },
//   },
//   show: {
//     opacity: 1,
//     scaleY: 1,
//     transformOrigin: 'top',
//     transition: { duration: 0.5, ease: 'linear' },
//   },
// };

// const menuItemAnimation = {
//   hidden: (i) => ({
//     opacity: 0,
//     y: -20, // Start slightly above the final position
//     transition: {
//       duration: 0.3,
//       delay: i * 0.05, // Stagger the animation of each item
//       ease: 'easeInOut'
//     },
//   }),
//   show: (i) => ({
//     opacity: 1,
//     y: 0, // End at the final position
//     transition: {
//       duration: 0.3,
//       delay: i * 0.05, // Stagger the animation of each item
//       ease: 'easeInOut'
//     },
//   }),
// };
const menuAnimation = {
  hidden: {
    opacity: 0, 
    scaleY: 0,
    transformOrigin: 'top',
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    scaleY: 1,
    transition: {
      duration: 0.3,
      when: "beforeChildren",
      staggerChildren: 0.1,
    },
  },
};

const menuItemAnimation = {
  hidden: (i) => ({
    opacity: 0,
    y: -20, 
    transition: {
      duration: 0.3,
  
    },
  }),
  show: (i) => ({
    opacity: 1,
    y: 0, 
    transition: {
      duration: 0.3,
 
    },
  }),
};


const SidebarMenu = ({ isLang, route, onClick, showAnimation, isOpen, setIsOpen }) => {

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);

  };

  useEffect(() => {
    if (!isOpen) {
      setIsMenuOpen(false);
    }
  }, [isOpen, isLang]);
  return (
    <>
      <div className="menu  m-1 mt-0 mb-0 link_active" onClick={toggleMenu} style={{ [isLang === 'en' ? 'paddingRight' : 'paddingLeft']: `${!isOpen ? '0px':'20px'}` }} >
        <div className={`menu_item bg-info ${!isOpen ? 'm-2':'m-1'}  mt-0 mb-0 `}>
          <span className={`link m-0 ${isMenuOpen ? 'link_text' : ''} ${!isOpen && 'flex justify-content-center align-items-center py-2'}`}>
            <div className="icon">{route.icon}</div>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  {isLang === 'ar' ? route.nameAr : route.nameEn}

                </motion.div>
              )}
            </AnimatePresence>
          </span>
        </div>
        {isOpen && (
          <motion.div
            className="angleDown flex justify-content-center align-items-center"
            animate={
              !isMenuOpen
                ? {
                  rotate: isLang === 'ar' ? 90 : -90,
                }
                : { rotate: 0 }
            }
          >
           
            <i className="pi pi-angle-down"></i>
          </motion.div>
        )}
      </div>{" "}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"

            
            animate="show"
            exit="hidden"
            className="menu_container "
          // style={{marginTop:'0.5px'}}
          >
            {route.subRoutes.map((subRoute, i) => { 
                if (!subRoute.permission || subRoute.permission.includes(1)) {
                  return (
                    <motion.div className="link_active1 mt-1" variants={menuItemAnimation} key={i} custom={i}>
                      <NavLink to={subRoute.path} onClick={onClick}className={`link ' }`} style={{ [isLang === 'ar' ? 'marginRight' : 'marginLeft']: '20px' }} >
                        <div className="icon">{subRoute.icon}</div>
                        <motion.div className="link_text " style={{ fontSize: 16 }}>{subRoute.name}
                          {isLang === 'ar' ? subRoute.nameAr : subRoute.nameEn} 
                        </motion.div>
                      </NavLink>
                    </motion.div>
                  )
                }
                return null
            }
            )}
          </motion.div>
        )}{" "}
      </AnimatePresence>
    </>
  );
};

export default SidebarMenu;
