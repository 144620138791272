import axios from 'axios';
import img from 'constants/Img';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { AutoComplete } from 'primereact/autocomplete';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import PreviewsImage from '../Brand/PreviewsImage';


const AddProducts = () => {
  let { suppliersAjex, fetchSuppliersAjex, fetchProductsAjex, productsAjex, categoriesAjex, fetchCategoriesAjex, fetchSubCategoriesAjex, subCategoriesAjex, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)

  let { isLang, selectedUpload, Profile } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/products/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const [value, setValue] = useState(0);

  const [selectedValue, setSelectedValue] = useState("");
  const [selectedValue2, setSelectedValue2] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);

  const items = [
    { label: <Link to={'/products'}>{t('products')}   </Link> },
    { label: <Link to={'/products/add'} className='p-2 border-round'>  {t('products_add')}  </Link> }
  ];

  const [selectedImage, setSelectedImage] = useState(null);
  const handleImageSelect = (event) => setSelectedImage(event.target.files[0]);


  const home = { icon: 'pi pi-home', url: '/' };
  const DiscountType = [
    { name: 'Value', value: 'VALUE' },
    { name: 'Precent', value: 'PERCENT' },
  ];
  const formik = useFormik({
    initialValues: {
      BrandProductTitleEn: '',
      BrandProductTitleAr: '',
      BrandProductDescEn: '',
      BrandProductDescAr: '',
      BrandProductOfferPrice: '',
      BrandProductPrice: '',
      BrandDescAr: '',
      IDCategory: '',
      IDSubCategory: '',
      IDBrand: '',
      BrandProductSerialCode: selectedValue,
      SupplierShareType: 'VALUE',
      SupplierShare: '',
      BrandProductLogo: selectedImage,
      BrandProductGallery: selectedUpload,
      IDSupplier: ''
    },
    onSubmit: async (values, { resetForm }) => {

      console.log(selectedImage);
      try {
        setLoading(true);
        let { data } = await axios.post(url, {
          IDBrand: values?.IDBrand,
          IDSubCategory: values?.IDSubCategory,
          BrandProductTitleEn: values?.BrandProductTitleEn,
          BrandProductTitleAr: values?.BrandProductTitleAr,
          BrandProductDescEn: values?.BrandProductDescEn,
          BrandProductDescAr: values?.BrandProductDescAr,
          BrandProductOfferPrice: values?.BrandProductOfferPrice,
          BrandProductPrice: values?.BrandProductPrice,
          BrandProductLogo: values?.BrandProductLogo,
          BrandProductGallery: selectedUpload,
          BrandProductSerialCode: selectedValue2 ? selectedValue2.value : selectedValue,
          SupplierShareType: values?.SupplierShareType,
          SupplierShare: values?.SupplierShare,
          IDSupplier: values?.IDSupplier ? values?.IDSupplier : Profile?.IDSupplier
        },
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate('/products')
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    formik.setFieldValue('BrandProductLogo', selectedImage);
  }, [selectedImage]);

  const defaultId = brandsAjex[0]?.IDBrand;
  const defaultIDCategory = categoriesAjex[0]?.IDCategory;
  const defaultIDSubCategory = subCategoriesAjex[0]?.IDSubCategory;

  useEffect(() => {
    fetchSuppliersAjex()
    fetchBrandsAjex()
    formik.setFieldValue("IDSupplier", suppliersAjex[0]?.IDSupplier)
    formik.setFieldValue("IDBrand", brandsAjex[0]?.IDBrand)
    fetchCategoriesAjex()

    if (categoriesAjex) {
      fetchSubCategoriesAjex(formik.values.IDCategory)
      formik.setFieldValue("IDCategory", categoriesAjex[0]?.IDCategory)
      formik.setFieldValue("IDSubCategory", subCategoriesAjex[0]?.IDSubCategory)
    }
  }, [defaultId, defaultIDCategory]);



  useEffect(() => {
    if (categoriesAjex) {
      formik.setFieldValue("IDSubCategory", subCategoriesAjex[0]?.IDSubCategory)
    }
    fetchProductsAjex()
  }, [defaultIDSubCategory]);




  const uniqueOptions = Array.from(new Set(productsAjex.map(item => item.BrandProductSerialCode))).map(code => {
    return { label: code, value: code };
  });

  const searchEvent = (event) => {
    let _filteredOptions;
    if (!event.query.trim().length) {
      _filteredOptions = [...uniqueOptions];
    } else {
      _filteredOptions = uniqueOptions.filter((option) =>
        option.label.toLowerCase().startsWith(event.query.toLowerCase())
      );
    }
    setFilteredOptions(_filteredOptions);
  };
  const [notFound, setnotFound] = useState(false)

  const handleChange = useCallback(async (e) => {
    setnotFound(false);
    if (e.value?.value) {
      setSelectedValue('');
      setSelectedValue2(e.value);
      setnotFound(true);
      fetchProductsAjex('', e.value?.value);

    } else {
      const isSelectedValueInOptions = filteredOptions.some(option => option.value === e.value);
      if (isSelectedValueInOptions) {
        setSelectedValue2('');
        setSelectedValue(e.value);
        setnotFound(true);
        fetchProductsAjex('', e.value);
      } else {
        setSelectedValue2('');
        setSelectedValue(e.value);
        setnotFound(true);
        fetchProductsAjex('', e.value);
      }

      fetchProductsAjex()
    }
  }, [selectedValue, selectedValue2]);
  return (
    <div>
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
        <BreadCrumb model={items} home={home} />
        <PreviewsImage type={'products'} />

        <form onSubmit={formik.handleSubmit} className='  w-11  m-auto'>
          <div className="grid   flex justify-content-center a ">
            <div className="lg:col-6 md:col-12 sm:col-12  input__Col flex justify-content-center align-items-center flex-column ">
              <div className=" shadow-4    flex justify-content-center align-items-center m-auto " style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                <div className="overflow-hidden flex justify-content-center align-items-center" style={{ height: '250px', position: 'relative', borderRadius: '10px', marginBottom: '30px', width: 'fit-content' }}>
                  {formik.values.BrandProductLogo ? (
                    <Image
                      preview
                      loading="lazy"
                      src={URL.createObjectURL(formik.values.BrandProductLogo)}
                      alt={formik.values.BrandProductLogo.name}
                      width='300px'
                      height='250px'
                      className=' mx-auto '
                      style={{ objectFit: 'contain' }}
                    />
                  ) :
                    <>
                      <img
                        loading="lazy"
                        src={img.DefaultImage}
                        alt={'default-image'}
                        className=' rounded-circle mx-auto '
                        style={{ border: '6px solid #fff', width: '300px', maxHeight: '172' }}
                      />
                    </>
                  }

                </div>
                <div style={{ position: 'absolute', right: '-5px', bottom: '0px' }}>
                  <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    onChange={handleImageSelect}
                    style={{ display: 'none' }}
                  />

                  <label htmlFor="file-input" className="btn__porfile" style={{ pointerEvents: 'all' }}>
                    <i className='pi pi-image color-wight'></i>
                  </label>
                </div>
              </div>
              <label htmlFor="ClientPicture" className="font-bold text-xl block mb-2">{isLang === "en" ? "Main Image" : "الصورة الرئيسية"}</label>
            </div>
          </div>

          <div className="grid  mt-5">
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductSerialCode" className="font-bold block mb-2">{t('BrandProductSerialCode')}</label>
              <AutoComplete
                value={selectedValue ? selectedValue : selectedValue2}
                suggestions={filteredOptions}
                completeMethod={searchEvent}
                field="label"
                dropdown
                placeholder={t('BrandProductSerialCode')}
                onChange={handleChange}
                className="w-full p-inputtext-sm"
              />


            </div>
            {
              notFound &&
              <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('products')}</label>
                <Dropdown
                  options={productsAjex?.map(item => ({
                    name: `${item.BrandProductTitle + ' , ' + item.SupplierName + ' , ' + item.BrandProductPrice}`,
                    value: item.IDBrandProduct,
                  }))}
                  id="IDBrandProduct"
                  name="IDBrandProduct"
                  optionLabel="name"
                  optionValue="value"
                  value={formik.values.IDBrandProduct} // Add this line 
                  onChange={async (e) => {
                    await formik.setFieldValue("IDBrandProduct", e.value)
                    const selectedData = await productsAjex.find(item => item.IDBrandProduct === e.value);
                    console.log(selectedData);
                    if (!Profile?.IDSupplier) {
                      await formik.setFieldValue("IDSupplier", selectedData.IDSupplier)
                    }
                    await formik.setFieldValue("IDBrand", selectedData.IDBrand)
                    await formik.setFieldValue("IDCategory", selectedData.IDCategory)
                    await formik.setFieldValue("IDSubCategory", selectedData.IDSubCategory)
                    await formik.setFieldValue("BrandProductPrice", selectedData.BrandProductPrice)
                    await formik.setFieldValue("BrandProductOfferPrice", selectedData.BrandProductOfferPrice)
                    await formik.setFieldValue("BrandProductTitleEn", selectedData.BrandProductTitleEn)
                    await formik.setFieldValue("BrandProductTitleAr", selectedData.BrandProductTitleAr)
                    await formik.setFieldValue("BrandProductDescEn", selectedData.BrandProductDescEn)
                    await formik.setFieldValue("BrandProductDescAr", selectedData.BrandProductDescAr)
                    await formik.setFieldValue("SupplierShareType", selectedData.SupplierShareType)
                    await formik.setFieldValue("SupplierShare", selectedData.SupplierShare)
                    setValue(selectedData.SupplierShare)
                  }}
                  onBlur={formik.handleBlur}
                  placeholder={t('Products_placeholder')}
                  className="w-full p-inputtext-sm"
                />
              </div>
            }
            {
              localStorage.getItem('Role') == 1 ?
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                  <label htmlFor="IDSupplier" className="font-bold block mb-2"> {t('Supplier')}</label>
                  <Dropdown filter
                    options={suppliersAjex?.map(item => ({
                      name: item.SupplierName,
                      value: item.IDSupplier,
                    }))}
                    id="IDSupplier"
                    name="IDSupplier"
                    optionLabel="name"
                    optionValue="value"
                    value={formik.values.IDSupplier}
                    onChange={(e) => {
                      formik.setFieldValue("IDSupplier", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    placeholder={t('brand_placeholder')}
                    className="w-full p-inputtext-sm"
                  />
                </div> :
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
                  <label htmlFor="IDSupplier" className="font-bold block mb-2"> {t('Supplier')}</label>
                  <Dropdown filter
                    options={suppliersAjex?.map(item => ({
                      name: item.SupplierName,
                      value: item.IDSupplier,
                    }))}
                    id="IDSupplier"
                    name="IDSupplier"
                    optionLabel="name"
                    optionValue="value"
                    value={Profile.IDSupplier}
                    onChange={(e) => {
                      formik.setFieldValue("IDSupplier", e.value)
                    }}
                    onBlur={formik.handleBlur}
                    placeholder={t('brand_placeholder')}
                    className="w-full p-inputtext-sm"
                    disabled
                  />
                </div>
            }
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
              <label htmlFor="IDBrand" className="font-bold block mb-2"> {isLang === "en" ? 'Brand' : 'العلامة التجارية'}</label>
              <Dropdown filter
                options={brandsAjex?.map(item => ({
                  name: item.BrandName,
                  value: item.IDBrand,
                }))}
                id="IDBrand"
                name="IDBrand"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDBrand} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDBrand", e.value)

                }}

                onBlur={formik.handleBlur}
                placeholder={t('brand_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2     ">
              <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('categories_title')}</label>
              <Dropdown filter
                options={categoriesAjex?.map(item => ({
                  name: item.CategoryName,
                  value: item.IDCategory,
                }))}
                id="IDCategory"
                name="IDCategory"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDCategory} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDCategory", e.value)
                  fetchSubCategoriesAjex(e.value)
                }}

                onBlur={formik.handleBlur}
                placeholder={t('Category_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2">
              <label htmlFor="IDCategory" className="font-bold block mb-2"> {t('subcategories_title')}</label>
              <Dropdown filter
                options={subCategoriesAjex?.map(item => ({
                  name: item.SubCategoryName,
                  value: item.IDSubCategory,
                }))}
                id="IDSubCategory"
                name="IDSubCategory"
                optionLabel="name"
                optionValue="value"
                value={formik.values.IDSubCategory} // Add this line 
                onChange={(e) => {
                  formik.setFieldValue("IDSubCategory", e.value)
                }}
                onBlur={formik.handleBlur}
                placeholder={t('SubCategory_placeholder')}
                className="w-full p-inputtext-sm"
              />
            </div>
            <div className="lg:col-12 md:col-12 sm:col-12 mt-2"></div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="BrandProductTitleEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
              <InputText
                name='BrandProductTitleEn'
                id="BrandProductTitleEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.BrandProductTitleEn}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductTitleAr" className="font-bold block mb-2">{t('label_categories_nameAr')}</label>
              <InputText
                name='BrandProductTitleAr'
                id="BrandProductTitleAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.BrandProductTitleAr}




              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductPrice" className="font-bold block mb-2">{t('price')}</label>
              <InputText
                name='BrandProductPrice'
                id="BrandProductPrice"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.BrandProductPrice}


              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductOfferPrice" className="font-bold block mb-2">{t('offer')}</label>
              <InputText
                name='BrandProductOfferPrice'
                id="BrandProductOfferPrice"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.BrandProductOfferPrice}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="SupplierShareType" className="font-bold block mb-2"> {t('SupplierShareType')} </label>
              <Dropdown filter
                id="SupplierShareType"
                name="SupplierShareType"
                value={formik.values.SupplierShareType}
                optionLabel="name"
                onChange={(e) => formik.setFieldValue("SupplierShareType", e.value)}
                onBlur={formik.handleBlur}
                options={DiscountType}
                placeholder={t('Discounttype')}
                className="w-full p-inputtext-sm"


              />

            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="SupplierShare" className="font-bold block mb-2">{t('SupplierShare')}</label>
              {
                formik.values.SupplierShareType === "VALUE" ?
                  <InputNumber
                    value={value}
                    name='SupplierShare'
                    onChange={(e) => {
                      formik.setFieldValue('SupplierShare', e.value)
                    }}
                    onBlur={formik.handleBlur}
                    id="SupplierShare"
                    className="w-full  p-inputtext-sm"
                  />
                  :
                  <InputNumber
                    value={value}
                    name='SupplierShare'
                    onChange={(e) => {
                      formik.setFieldValue('SupplierShare', e.value)
                    }}
                    onBlur={formik.handleBlur}
                    id="SupplierShare"
                    className="w-full  p-inputtext-sm"
                    prefix="%" />
              }


            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductDescEn" className="font-bold block mb-2">{t('desc_en')}</label>
              <InputTextarea
                name='BrandProductDescEn'
                id="BrandProductDescEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.BrandProductDescEn}


              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="BrandProductDescAr" className="font-bold block mb-2">{t('desc_ar')}</label>
              <InputTextarea
                name='BrandProductDescAr'
                id="BrandProductDescAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.BrandProductDescAr}


              />
            </div>
          </div>

          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={'/products'}>
              <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>

    </div>
  )
}

export default AddProducts