import ChangeSupplier from 'Page/Admin/Order/ChangeSupplier';
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

const SupplierOrderList = () => {
    let { id } = useParams()
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [open, setOpenModal] = useState(false);
    let { fetchProductsAjex } = useContext(FetchApi)
    const [IDOrderDetail, setIDOrderDetail] = useState(null)
    const [value, setValue] = useState(0);
    const [expandedRowId, setExpandedRowId] = useState(null);
    const allowExpansion = (rowData) => {
        return rowData.OrderDetailAttributes.length >= 0;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3 bg-black-alpha-10 w-5">
                <h4 className='mb-2'>  Product Attributes  </h4>
                <DataTable size='small' value={data?.OrderDetailAttributes}>
                    <Column field="ProductAttribute" header={t('attributes')} style={{ minWidth: '10rem', textAlign: 'center' }} ></Column>
                    <Column field="ProductAttributeValue" header={t('value')} style={{ minWidth: '10rem', textAlign: 'center' }} ></Column>
                </DataTable>
            </div>
        );
    };
    const onRowExpand = (e) => {
        setExpandedRowId(e.data.IDOrderDetail);
    };

    const onRowCollapse = () => {
        setExpandedRowId(null);
    };

    const calculateExpandedRows = () => {
        let rows = {};
        if (expandedRowId !== null) {
            rows[expandedRowId] = true;
        }
        return rows;
    };
    const changeModel = (rowData) => {
        setOpenModal(true)
        fetchProductsAjex('', rowData?.BrandProductSerialCode)
        setIDOrderDetail(rowData?.IDOrderDetail)
        setValue(rowData?.IDBrandProduct)
    }
    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button icon="pi pi-pencil" onClick={e => changeModel(rowData)} severity="danger" rounded outlined text raised className="mr-2" />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0"> </h4>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" className="  p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={isLang === "en" ? "Search..." : ' بحـــث... '} />
            </span>
        </div>
    );

    const fetchData = async () => {

        const url = `${process.env.REACT_APP_API_URL}/orders/suppliers`;

        let data = await axios.post(url, { IDPage: page + 1, IDSupplier: id, Type: 'order' },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Orders);
        setPagesNum(data?.data?.Response?.Pages)

    }
    useEffect(() => {
        fetchData();
    }, [isLang, page])

    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('CANCELLED'), value: 'CANCELLED' },
        // { label: t('pending'), value: 'PENDING' },
    ];

    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/orders/details/supplier/status`, {
            IDOrderDetail: rowData?.IDOrderDetail,
            OrderDetailStatus: e.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.OrderDetailStatus === 'ACTIVE' ? t('active') : rowData?.OrderDetailStatus === 'CANCELLED' ? t('CANCELLED') : t('pending')

        return editingRow === rowData?.IDOrderDetail ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.OrderDetailStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${rowData?.OrderDetailStatus === 'ACTIVE' ? t('active') : rowData?.OrderDetailStatus === 'CANCELLED' ? t('CANCELLED') : t('pending')}`}

                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded
                severity={getSeverity(rowData.OrderDetailStatus)}
            // onClick={() => setEditingRow(rowData?.IDOrderDetail)}
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    {/* <i className='pi pi-angle-down'></i> */}
                </div>
            </Tag>
        );
    };

    const getSeverity = (status) => {
        switch (status) {
            case 'CANCELLED':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';

            case '':
                return 'info';
        }
    };
    const price = (data) => {
        if (data?.OrderDetailOfferPrice === 0) {
            return (<span>{data?.OrderDetailPrice}</span>)
        } else {
            return (<span>{data?.OrderDetailOfferPrice}</span>)
        }
    };

    return (
        <div className='mb-8 '>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <DataTable
                    className="custom-datatable"
                    showGridlines
                    ref={dt}
                    dataKey='IDOrderDetail' 
                    value={Data} 
                    globalFilter={globalFilter}
                    selectionMode='single'
                    expandedRows={calculateExpandedRows()}
                    onRowExpand={onRowExpand}
                    onRowCollapse={onRowCollapse}
                    rowExpansionTemplate={rowExpansionTemplate}
              
                    tableStyle={{ minWidth: '60rem' }}

                    header={header}>
                    <Column expander={allowExpansion} header={t('attributes')} style={{ minWidth: 'rem', textAlign: 'center' }} />
                    <Column field="BrandProductSerialCode" header={t('BrandProductSerialCode')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="BrandName" header={t('brand')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column field="SupplierName" header={t('SupplierName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="BrandProductTitle" header={t('BrandProductTitle')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column field="CategoryName" header={t('categories_title')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="SubCategoryName" header={t('SubCategory')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="OrderDetailQuantity" header={t('OrderDetailQuantity')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailPrice" header={t('price')} body={price} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailOfferPrice" header={t('offer')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column field="OrderDetailTotalAmount" header={t('totalAmount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="SupplierShare" header={t('SupplierShare')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {
                        localStorage.getItem('Role') == 1 &&
                        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                    }
                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10}
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
            <ChangeSupplier value={value} setValue={setValue} fetchData={fetchData} toast={toast} t={t} open={open} setOpenModal={setOpenModal} IDOrderDetail={IDOrderDetail} />

        </div>
    );
};


export default SupplierOrderList
