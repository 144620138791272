import { useTranslation } from 'react-i18next'
import { LocalizationContext } from 'context/LangChange'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useState } from 'react'
import { Button } from 'primereact/button'
import { FetchApi } from 'context/FetchApi'
import ChangeSupplier from './ChangeSupplier'
const OrdersProducts = ({ Data,toast ,fetchData}) => {
    let { t } = useTranslation()
    const [expandedRowId, setExpandedRowId] = useState(null);
    let { fetchProductsAjex } = useContext(FetchApi)
    const [open, setOpenModal] = useState(false);
    const [value, setValue] = useState(0);

    const allowExpansion = (rowData) => {
        return rowData.OrderDetailAttributes.length >= 0 ;
    };
    const rowExpansionTemplate = (data) => {
        return (
            <div className="p-3 bg-black-alpha-10 w-5">
                <h4 className='mb-2'>  Product Attributes  </h4>
                <DataTable size='small' value={data?.OrderDetailAttributes}>
                    <Column field="ProductAttribute" header={t('attributes')} style={{ minWidth: '10rem', textAlign: 'center' }} ></Column>
                    <Column field="ProductAttributeValue" header={t('value')} style={{ minWidth: '10rem', textAlign: 'center' }} ></Column>
                </DataTable>
            </div>
        );
    };
    const onRowExpand = (e) => {
        setExpandedRowId(e.data.IDOrderDetail);
    };

    const onRowCollapse = () => {
        setExpandedRowId(null);
    };

    const calculateExpandedRows = () => {
        let rows = {};
        if (expandedRowId !== null) {
            rows[expandedRowId] = true;
        }
        return rows;
    };
    // const price = (data) => {
    //     if (data?.OrderDetailOfferPrice === 0) {
    //         return (<span>{data?.OrderDetailPrice}</span>)
    //     } else {
    //         return (<span>{data?.OrderDetailOfferPrice}</span>)
    //     }
    // };
    const [IDOrderDetail, setIDOrderDetail] = useState(null)
    const changeModel = (rowData) => {
        setOpenModal(true)
        fetchProductsAjex('', rowData?.BrandProductSerialCode)
        setIDOrderDetail(rowData?.IDOrderDetail)
        setValue(rowData?.IDBrandProduct)
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Button icon="pi pi-pencil" onClick={e => changeModel(rowData)} severity="danger" rounded outlined text raised className="mr-2" />
            </div>
        );
    };


    return (
        <div>
            <div className="card">
                <h4 className='mb-2'> {t('products')} </h4>
                <DataTable
                    className='custom-datatable'
                    value={Data}
                    dataKey='IDOrderDetail'
                    showGridlines
                    selectionMode='single'
                    expandedRows={calculateExpandedRows()}
                    onRowExpand={onRowExpand}
                    onRowCollapse={onRowCollapse} 
                    rowExpansionTemplate={rowExpansionTemplate}
                    tableStyle={{ minWidth: '60rem' }}
                >
                    <Column expander={allowExpansion} header={t('attributes')} style={{ minWidth: 'rem', textAlign: 'center' }} />
                    <Column field="BrandProductSerialCode" header={t('Serial')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="SupplierName" header={t('SupplierName')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandProductTitle" header={t('BrandProductTitle')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="BrandName" header={t('brand')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailQuantity" header={t('OrderDetailQuantity')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailPrice" header={t('price')} /* body={price} */ style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column field="OrderDetailOfferPrice" header={t('offer')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    {/* <Column field="OrderDetailTotalAmount" header={t('totalAmount')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="SupplierShare" header={t('SupplierShare')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} header={t('changeSuppliers')} style={{ minWidth: 'rem', textAlign: 'center' }} />
                </DataTable>
            </div>

            <ChangeSupplier value={value} setValue={setValue} fetchData={fetchData}  toast={toast} t={t} open={open} setOpenModal={setOpenModal} IDOrderDetail={IDOrderDetail} />
        </div>
    )
}

export default OrdersProducts