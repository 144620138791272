import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { Toolbar } from 'primereact/toolbar'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AddPromoCodeProducts from './AddPromoCodeProducts'

const PromoCodeProducts = ({ modalDone, setModalDone, id }) => {
    let { isLang } = useContext(LocalizationContext)
    let { t } = useTranslation()
    const dt = useRef(null);
    const [Data, setData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const toast = useRef(null);
    // Add new Tools
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Button raised onClick={() => setModalOpen(true)} label={t('brand_add')} severity="danger" icon="pi pi-plus" size='small' />
            </div>
        );
    };

    const fetchData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/promos/products`;
        let data = await axios.post(url, { IDPromoCode: id },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response);
    }
    useEffect(() => {
        if (modalDone) {
            fetchData();
        }
    }, [isLang, id])


    return (
        <div>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />

            <Dialog
                visible={modalDone}
                style={{ width: '50rem' }}
                modal
                onHide={setModalDone}
                dir={isLang === "en" ? 'ltr' : 'rtl'}
                header={t('promoCodeProdacts')}
            >
                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    rowHover
                    showGridlines >
                    <Column field="BrandName" header={t('Brand')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="SupplierName" header={t('SupplierName')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BrandProductTitle" header={t('BrandProductTitle')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>

                </DataTable>
            </Dialog>

            <AddPromoCodeProducts
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                IDPromoCode={id}
                fetchData={fetchData}
            />
        </div>
    )
}

export default PromoCodeProducts