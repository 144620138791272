import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OrderDetailsStatus = ({ Data, id,toast,fetchData }) => {
    let { t } = useTranslation()
    let {isLang}=useContext(LocalizationContext)
    const [deleteLoadind, setdeleteLoadind] = useState(false);

    const [editingRow, setEditingRow] = useState(null);
    const [deleteusersDialog, setDeleteusersDialog] = useState(false);
    const [statusChange, setStatusChange] = useState('');
    const hideDeleteusersDialog = () => setDeleteusersDialog(false);

    const getStatusOptions = (status) => {
        switch (status) {
            case 'PENDING':
                return [
                    { label: t('ACCEPT'), value: 'ACCEPTED' },
                    { label: t('REJECT'), value: 'REJECTED' }
                ];
            case 'ACCEPTED':
                return [
                    { label: t('OUT_FOR_DELIVERY'), value: 'OUT_FOR_DELIVERY' },
                    /*          { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                             { label: t('DELIVERED'), value: 'DELIVERED' } */
                ];
            case 'OUT_FOR_DELIVERY':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    // { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'ARRIVED_AT_STATION':
                return [
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            case 'DELIVERED':
                return [
                    { label: t('ARRIVED_AT_STATION'), value: 'ARRIVED_AT_STATION' },
                    { label: t('DELIVERED'), value: 'DELIVERED' }
                ];
            default:
                return [];
        }
    };
    const statusBodyTemplate = (status) => {
        const options = getStatusOptions(status);
        const editingStatus =
            status === 'ACCEPTED' ? t('ACCEPTED') :
                status === 'CANCELLED' ? t('CANCELLED') :
                    status === 'REJECTED' ? t('REJECTED') :
                        status === 'OUT_FOR_DELIVERY' ? t('OUT_FOR_DELIVERY') :
                            status === 'ARRIVED_AT_STATION' ? t('ARRIVED_AT_STATION') :
                                status === 'CANCELLED' ? t('CANCELLED') :
                                    status === 'RETURNED' ? t('RETURNED') :
                                        status === 'DELIVERED' ? t('DELIVERED') : t('pending');

        return editingRow === id ? (
            <Dropdown
                value={status}
                options={options}
                onChange={(e) => onStatusChange(status, e)}
                placeholder={editingStatus}
                className="p-inputtext-sm dropdown_filter "
                style={{ fontSize: '12px', lineHeight: '20px' }}
            />
        ) : (
            <>
                <Tag
                    rounded
                    severity={getSeverity(status)}
                    onClick={() => {
                        if (
                            status === 'ACCEPTED' ||
                            status === 'OUT_FOR_DELIVERY' ||
                            status === 'ARRIVED_AT_STATION' ||
                            status === 'PENDING'
                        ) {
                            setEditingRow(id);
                        }
                    }}

                >
                    <div className='flex justify-content-center align-items-center gap-2'>
                        <span> {editingStatus}</span>
                        <i className='pi pi-angle-down'></i>
                    </div>
                </Tag>
            </>
        );
    };
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        setDeleteusersDialog(true);
        setStatusChange(e.value)
    };
    const getSeverity = (status) => {
        switch (status) {
            case 'OUT_FOR_DELIVERY':
                return 'help';
            case 'REJECTED':
                return 'danger';
            case 'DELIVERED':
                return 'success';
            case 'ARRIVED_AT_STATION':
                return 'Info';
            case 'PENDING':
                return 'warning';
            case 'ACCEPTED':
                return 'success';
            case 'CANCELLED':
                return 'danger';
            case 'RETURNED':
                return 'danger';
        }
    };
    const deleteusers = async (rowData, e) => {
        setdeleteLoadind(true)

        await axios.post(`${process.env.REACT_APP_API_URL}/orders/status`, {
            IDOrder: id,
            OrderStatus: statusChange
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
                setDeleteusersDialog(false);
                setdeleteLoadind(false)
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
            setdeleteLoadind(false)

        })
    };
    const deleteusersDialogFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" severity="danger" outlined onClick={hideDeleteusersDialog} />
            <div className="btn_submit">
                <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={deleteLoadind} raised icon="pi pi-check" severity="danger" onClick={deleteusers} />
            </div>
        </div>
    );


 
    return (
        <div>
            <div className="info flex flex-row gap-8 mt-35">
                {statusBodyTemplate(Data?.OrderStatus)}
            </div>


            <Dialog dir={isLang == "en" ? 'ltr' : 'rtl'} modal className="p-fluid" visible={deleteusersDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isLang == "en" ? 'Change Status' : 'تأكيد الحذف'} footer={deleteusersDialogFooter} onHide={hideDeleteusersDialog}>
                <div className="confirmation-content   flex justify-content-start  gap-3 align-items-center">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                    <span>
                        {isLang === "en" ? "Are you sure you want to change the order status?  " : 'هل أنت متأكد أنك تريد تغيير حالة الطلب؟  '}
                    </span>
                </div>
            </Dialog>
        </div>
    )
}

export default OrderDetailsStatus