import axios from 'axios'
import { LocalizationContext } from 'context/LangChange'
import { BreadCrumb } from 'primereact/breadcrumb'
import { Toast } from 'primereact/toast'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import OrderAddress from './OrderAddress'
import OrderDetailsStatus from './OrderDetailsStatus'
import OrderInfo from './OrderInfo'
import OrdersProducts from './OrdersProducts'

const OrderDetails = () => {
    let { id } = useParams()
    let { t } = useTranslation()
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext);
    const [Data, setData] = useState(null);
    const [address, setAddress] = useState(null);

    const fetchData = async () => {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/orders/details/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success === true) {
            setData(data?.Response[0]);
            setAddress([data?.Response[0]?.OrderAddress]);
        }
    }
    useEffect(() => {
        fetchData();
        window.scroll(0, 0)
    }, [isLang, id])

    const items = [
        { label: <Link to={`/orders`} className='text-bluegray-400'> {t('order')}</Link> },
        { label: <Link to={`/orders/details/${id}`} className='  text-bluegray-800 p-2 border-round'>{t('orderDetails')}</Link> }
    ];
    const home = { icon: 'pi pi-home', url: '/' };
    return (
        <>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="mb-3 px-5 mt-3">
                <BreadCrumb model={items} home={home} />
            </div>
            <div className="app__PersonalData px-6 mt-0 pt-0">
                <OrderDetailsStatus Data={Data} id={id} toast={toast} fetchData={fetchData} />
                <OrderInfo Data={Data} />
                <OrderAddress address={address} />
                <OrdersProducts  Data={Data?.OrderDetails} toast={toast} fetchData={fetchData} />
            </div >
        </>
    )
}

export default OrderDetails
