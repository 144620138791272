
import axios from 'axios';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BrandGallery from './BrandGallery';

const Brand = () => {

    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [idGallery, setGalleryId] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const [modalDone, setModalDone] = useState(false);
    const inputRef = useRef(null);
    const [selectedStatus, setSelectedStatus] = useState('');

    const handelRating = (data) => {
        setModalDone(true)
        setGalleryId(data?.IDBrand);
        console.log(data?.IDBrand);
    }
    // Add new brands
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/brands/add">
                    <Button raised label={t('brand_add')} severity="danger" icon="pi pi-plus" size='small' />
                </Link>
                <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="danger" icon="pi pi-history" size='small' />

            </div>
        );
    };

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (

            <div className="flex gap-2 justify-content-center align-content-center">
                <Tooltip target=".disabled-button" autoHide={false} />
                <Link to={`/brands/edit/${rowData.IDBrand}`}>
                    <Button tooltip={t('brand_edit')} icon="pi pi-pencil" severity="danger" tooltipOptions={{ position: t('tooltip') }} rounded outlined text raised className="mr-2" />
                </Link>
                <Button onClick={(e) => handelRating(rowData)} tooltip={t('gallery')} icon="pi pi-images" severity="danger" tooltipOptions={{ position: t('tooltip') }} rounded outlined text raised className="mr-2" />
            </div>
        );
    };
    const statusOptionsFiter = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
        { label: t('pending'), value: 'PENDING' },
    ];
    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div>
                <Dropdown
                    options={statusOptionsFiter}
                    value={selectedStatus}
                    onChange={e => {
                        fetchData( '', e.value)
                        setSelectedStatus(e.value) 
                        inputRef.current.value = ''; 
                    }}
                    placeholder={selectedStatus ? statusOptionsFiter.find(option => option.value === selectedStatus).label : "Select Status "}
                    className="p-inputtext-sm w-12rem"
                />
            </div>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    className="w-full p-inputtext-sm"
                    onKeyDown={(e) => fetchData(e.target.value)}
                    placeholder={t('search_placeholder')}
                    ref={inputRef}

                />
            </span>
        </div>
    );

    const fetchData = useCallback(async (SearchKey, BrandStatus) => {
        if (SearchKey) {
            setSelectedStatus('') 
          
        }
        const url = `${process.env.REACT_APP_API_URL}/brands`;
        let data = await axios.post(url, { IDPage: page + 1, SearchKey, BrandStatus },
            {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'multipart/form-data', 
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.Brands);
        setPagesNum(data?.data?.Response?.Pages)
    }, []);

    useEffect(() => {
        fetchData();
    }, [isLang, page])


    const [editingRow, setEditingRow] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('inactive'), value: 'INACTIVE' },
    ];


    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/brands/status`, { IDBrand: rowData?.IDBrand, BrandStatus: e?.value }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.BrandStatus === 'ACTIVE' ? t('active') : rowData?.BrandStatus === 'INACTIVE' ? t('inactive') : t('pending')

        return editingRow === rowData?.IDBrand ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.BrandStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${editingStatus}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded
                severity={getSeverity(rowData.BrandStatus)}
                onClick={() => setEditingRow(rowData?.IDBrand)}
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    <i className='pi pi-angle-down'></i>
                </div>
            </Tag>
        );
    };



    const getSeverity = (status) => {
        switch (status) {
            case 'INACTIVE':
                return 'danger';

            case 'ACTIVE':
                return 'success';

            case 'PENDING':
                return 'warning';
            case '':
                return 'info';
        }
    };
    // handel image in tabel
    const imageBodyTemplate = (rowData) => {
        return <div className='flex justify-content-center'>
            {
                rowData.BrandLogo ?
                    <Image
                        preview
                        loading="lazy"
                        src={rowData.BrandLogo}
                        alt={'brand iamge'}
                        width='90'
                        height='100%'
                        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
                    /> :
                    <img src={img.DefaultImage} alt="" srcset="" />
            }
        </div>
    };
    const homeBodyTemplate = (rowData) => (
        <ToggleButton
            size='small'
            className={`w-3rem ${rowData.HomeBrand === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
            checked={rowData.HomeBrand === 1}
            onChange={() => handleToggle(rowData)}
        />
    );
    const [checkedItems, setCheckedItems] = useState({});
    const handleToggle = async (rowData) => {
        const updatedCheckedItems = { ...checkedItems, [rowData.id]: !checkedItems[rowData.id] };
        setCheckedItems(updatedCheckedItems);
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/home/${rowData?.IDBrand}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })

    };

    const homeBodyTemplateFooter = (rowData) => (
        <ToggleButton
            size='small'
            className={`w-3rem ${rowData.TopBrand === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
            checked={rowData.TopBrand === 1}
            onChange={() => handleToggleFooter(rowData)}
        />
    );
    const [checkedItemsFooter, setCheckedItemsFooter] = useState({});
    const handleToggleFooter = async (rowData) => {
        const updatedCheckedItems = { ...checkedItemsFooter, [rowData.id]: !checkedItemsFooter[rowData.id] };
        setCheckedItemsFooter(updatedCheckedItems);
        await axios.get(`${process.env.REACT_APP_API_URL}/brands/top/${rowData?.IDBrand}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })

    };
    const resetData = () => {
        fetchData();
        setSelectedStatus('')  
        inputRef.current.value = ''; // مسح محتوى حقل الإدخال
    }
    return (
        <div  >
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rowHover
                    showGridlines
                    header={header}>
                    <Column field="BrandLogo" header={t('logo')} body={imageBodyTemplate}></Column>
                    <Column field="BrandName" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    {/* <Column field="BrandDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
                    <Column field="BrandNumber" header={t('phone')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="BrandEmail" header={t('email')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="HomeBrand" header={t('home')} body={homeBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="TopBrand" header={t('top_brand')} body={homeBodyTemplateFooter} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    <Column field="BrandStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

                </DataTable>
                <Paginator dir='ltr'
                    first={page * 10}
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>

            <BrandGallery
                modalDone={modalDone}
                setModalDone={setModalDone}
                id={idGallery}
            />

        </div>
    )
}


export default Brand