import { LocalizationContext } from 'context/LangChange'
import { format, isValid, parseISO } from 'date-fns'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const OrderInfo = ({ Data }) => {
    let { t } = useTranslation()
    let { isLang } = useContext(LocalizationContext)
    return (
        <div className="info_contactus mt-35">
            <div className="grid  mt-5  ">
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-user'></i>
                        </div>
                        <div>
                            <div className="header"> {t('name')}   </div>
                            <div className="body link" dir='ltr'>
                                <Link to={`/clients/details/${Data?.IDClient}`} style={{ textDecoration: 'underline' }} >
                                    {Data?.ClientName}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-phone'></i>
                        </div>
                        <div>
                            <div className="header"> {t('phone')}   </div>
                            <div className="body" dir='ltr'> {Data?.ClientPhone}  </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-calendar-plus'></i>
                        </div>
                        <div>
                            <div className="header"> {isLang === "en" ? 'Order Date' : 'تاريخ الطلب'}   </div>
                            <div className="body" dir='ltr'>
                                {isValid(parseISO(Data?.OrderDate)) ? format(parseISO(Data?.OrderDate), 'dd-MM-yyyy HH:mm') : 'Invalid Date'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-shopping-cart'></i>
                        </div>
                        <div>
                            <div className="header"> {t('ShippingAmount')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.OrderShippingAmount}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-dollar'></i>
                        </div>
                        <div>
                            <div className="header"> {t('amount')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.OrderAmount}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-gift'></i>
                        </div>
                        <div>
                            <div className="header"> {t('discount')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.OrderOfferAmount}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi  pi-money-bill'></i>
                        </div>
                        <div>
                            <div className="header"> {t('totalAmount')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.OrderTotalAmount}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-wallet'></i>
                        </div>
                        <div>
                            <div className="header"> {t('ledger_Batchnumber')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.OrderBatchNumber}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                    <div className="conatent ">
                        <div className="icons">
                            <i className='pi pi-wallet'></i>
                        </div>
                        <div>
                            <div className="header"> {t('PaymentMethod')}   </div>
                            <div className="body" dir='ltr'>
                                {Data?.PaymentMethod}
                            </div>
                        </div>
                    </div>
                </div>
                {
                    Data?.OrderStatus === "RETURNED" &&
                    <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
                        <div className="conatent ">
                            <div className="icons">
                                <i className='pi pi-envelope'></i>
                            </div>
                            <div>
                                <div className="header"> {t('OrderReturnReason')}   </div>
                                <div className="body" dir='ltr'>
                                    {Data?.OrderReturnReason}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderInfo