
import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BrandRating from './BrandRating';

const Products = () => {
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const dt = useRef(null);
  const toast = useRef(null);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [Data, setData] = useState(null);
  const [page, setPage] = useState(0);
  const [pagesNum, setPagesNum] = useState(null);
  const [byFilter, setByFilter] = useState(null);
  let { categoriesAjex, fetchCategoriesAjex, productsAjex, fetchSubCategoriesAjex, subCategoriesAjex, fetchProductsAjex, suppliersAjex, fetchSuppliersAjex, brandsAjex, fetchBrandsAjex } = useContext(FetchApi)
  const [modalRating, setModalRating] = useState(false);
  const [dataSocialMedia, setdataSocialMedia] = useState(null);

  const handelRating = (data) => {
    setdataSocialMedia(data)
    setModalRating(true)
  }

  // Add new brands
  const leftToolbarTemplate = () => {
    return (
      <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
        <Link to="/products/add">
          <Button raised label={t('products_add')} severity="danger" icon="pi pi-plus" size='small' />
        </Link>
        <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="danger" icon="pi pi-history" size='small' />
      </div>
    );
  };

  // handel btn action in tabel 
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex gap-2 justify-content-center align-content-center">
        <Tooltip target=".disabled-button" autoHide={false} />

        <Link to={`/products/edit/${rowData.IDBrandProduct}`}>
          <Button tooltip={t('brand_edit')} tooltipOptions={{ position: t('tooltip') }} icon="pi pi-pencil" severity="danger" rounded outlined text raised className="mr-2" />
        </Link>
        <Link to={`/products/attributes/${rowData.IDBrandProduct}`}>
          <Button tooltip={t('attributes')} tooltipOptions={{ position: t('tooltip') }} icon="pi  pi-eraser" severity="danger" rounded outlined text raised className="mr-2" />
        </Link>
        <Button tooltip={t('Rating')} tooltipOptions={{ position: t('tooltip') }} icon="pi pi-star" severity="danger" onClick={() => handelRating(rowData)} rounded outlined text raised className="mr-2" />

      </div>
    );
  };
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const OptionsFilter = [
    { label: t('products'), value: 1 },
    { label: t('Brand'), value: 2 },
    { label: t('SupplierName'), value: 3 },
    { label: t('BrandProductSerialCode'), value: 4 },
    { label: t('categories_title'), value: 5 },
  ];
  const [typeFilter, setType] = useState('');
  const header = (
    <>

      <div className="grid  flex justify-content-between flex-row-reverse ">
        <div className="lg:col-2 md:col-12 sm:col-12     ">
          <Dropdown filter
            value={byFilter}
            options={OptionsFilter?.map(item => ({
              name: item.label,
              value: item.value,
            }))}
            id="IDProduct"
            name="IDProduct"
            optionLabel="name"
            optionValue="value"
            onChange={(e) => {
              setByFilter(e.value)
            }}
            placeholder={isLang === "en" ? 'Filter by' : 'اختر المنتج'}
            className="p-inputtext-sm w-full"
          />
        </div>
        {
          byFilter === 1 &&
          <div className="lg:col-3 md:col-12 sm:col-12     ">
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                className="w-full p-inputtext-sm"
                onKeyDown={(e) => fetchData('', '', '', e.target.value)}
                placeholder={t('search_placeholder')}
              />
            </span>
          </div>
        }

        {
          byFilter === 2 &&
          <div className="lg:col-3 md:col-12 sm:col-12     ">
            <Dropdown filter
              value={selectedProduct}
              options={brandsAjex?.map(item => ({
                name: item.BrandName,
                value: item.IDBrand,
              }))}
              id="IDBrand"
              name="IDBrand"
              optionLabel="name"
              optionValue="value"
              onChange={(e) => {
                setSelectedProduct(e.value)
                fetchData(e.value)
              }}
              placeholder={isLang === "en" ? 'Select a brand' : 'اختر براند'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }

        {
          byFilter === 3 &&
          <div className="lg:col-3 md:col-12 sm:col-12     ">
            <Dropdown filter
              value={selectedProduct}
              options={suppliersAjex?.map(item => ({
                name: item.SupplierName,
                value: item.IDSupplier,
              }))}
              id="IDBrand"
              name="IDBrand"
              optionLabel="name"
              optionValue="value"
              onChange={(e) => {
                setSelectedProduct(e.value)
                fetchData(null, e.value)
              }}
              placeholder={isLang === "en" ? 'Select a supplier' : 'حدد المورد'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }
        {
          byFilter === 4 &&
          <div className="lg:col-3 md:col-12 sm:col-12     ">
            <Dropdown filter
              value={selectedProduct}
              options={productsAjex?.map(item => ({
                name: item.BrandProductSerialCode,
                value: item.BrandProductSerialCode,
              }))}
              id="IDBrand"
              name="IDBrand"
              optionLabel="name"
              optionValue="value"
              onChange={(e) => {
                setSelectedProduct(e.value)
                fetchData(null, null, e.value)
              }}
              placeholder={isLang === "en" ? 'Select a serial code' : 'حدد الرمز التسلسلي'}
              className="p-inputtext-sm w-full"
            />
          </div>
        }

        {
          byFilter === 5 &&
          <div className="lg:col-6 md:col-12 sm:col-12     ">
            <div className='grid bg-info w-100'>
              <div className="lg:col-6 md:col-12 sm:col-12     ">
                <Dropdown filter
                  value={selectedCategory}
                  options={categoriesAjex?.map(item => ({
                    name: item.CategoryName,
                    value: item.IDCategory,
                  }))}
                  id="IDBrand"
                  name="IDBrand"
                  optionLabel="name"
                  optionValue="value"
                  onChange={(e) => {
                    setSelectedCategory(e.value)
                    fetchSubCategoriesAjex(e.value)

                  }}
                  placeholder={isLang === "en" ? 'Select a subcategory' : 'حدد الرمز التسلسلي'}
                  className="p-inputtext-sm w-full"
                />
              </div>

              <div className="lg:col-6 md:col-12 sm:col-12     ">
                <Dropdown filter
                  value={selectedSubCategory}
                  options={subCategoriesAjex?.map(item => ({
                    name: item.SubCategoryName,
                    value: item.IDSubCategory,
                  }))}
                  id="IDBrand"
                  name="IDBrand"
                  optionLabel="name"
                  optionValue="value"
                  onChange={(e) => {
                    setSelectedSubCategory(e.value)
                    fetchData(null, null, null, null, e.value)

                  }}
                  placeholder={isLang === "en" ? 'Select a subcategory' : 'حدد الرمز التسلسلي'}
                  className="p-inputtext-sm w-full"
                />
              </div>

            </div>
          </div>
        }
      </div>

    </>
  );

  const fetchData = useCallback(async (IDBrand, IDSupplier, BrandProductSerialCode, SearchKey, IDSubCategory) => {
    const url = `${process.env.REACT_APP_API_URL}/brands/products`;
    let requestBody = { IDPage: page + 1 };

    // Conditionally add each parameter if it's not null or empty
    if (IDBrand) requestBody.IDBrand = IDBrand;
    if (IDSupplier) requestBody.IDSupplier = IDSupplier;
    if (BrandProductSerialCode) requestBody.BrandProductSerialCode = BrandProductSerialCode;
    if (SearchKey) requestBody.SearchKey = SearchKey;
    if (IDSubCategory) requestBody.IDSubCategory = IDSubCategory;

    let data = await axios.post(url, requestBody,
      {
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'multipart/form-data', 
          'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
        },
      })
    setData(data?.data?.Response?.BrandProducts);
    setPagesNum(data?.data?.Response?.Pages)
  }, [typeFilter, page]);

  useEffect(() => {
    fetchData();
  }, [isLang, page, typeFilter])

  useEffect(() => {
    if (byFilter === 2) {
      fetchBrandsAjex()
    } else if (byFilter === 3) {
      fetchSuppliersAjex()
    } else if (byFilter === 4) {
      fetchProductsAjex()
    } else if (byFilter === 5) {
      fetchCategoriesAjex()
    }
  }, [byFilter])
  const [editingRow, setEditingRow] = useState(null);
  const resetData = () => {
    fetchData();
    setByFilter(null)
    setSelectedProduct(null)
    setSelectedCategory(null)
    setSelectedSubCategory(null)
  }
  const statusOptions = [
    { label: t('active'), value: 'ACTIVE' },
    { label: t('inactive'), value: 'INACTIVE' },
  ];

  const onStatusChange = async (rowData, e) => {
    setEditingRow(null);
    await axios.post(`${process.env.REACT_APP_API_URL}/brands/products/status`, { IDBrandProduct: rowData?.IDBrandProduct, BrandProductStatus: e?.value }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {

      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

    })
  };


  const statusBodyTemplate = (rowData) => {
    const editingStatus = rowData?.BrandProductStatus === 'ACTIVE' ? t('active') : rowData?.BrandProductStatus === 'INACTIVE' ? t('inactive') : t('pending')

    return editingRow === rowData?.IDBrandProduct ? (
      <Dropdown
        value={editingStatus}
        options={statusOptions.filter((option) => option.value !== rowData?.BrandProductStatus)}
        onChange={(e) => onStatusChange(rowData, e)}
        placeholder={`${editingStatus}`}
        className="p-inputtext-sm "
      />
    ) : (
      <Tag rounded
        severity={getSeverity(rowData.BrandProductStatus)}
        onClick={() => {
          if (localStorage.getItem('Role')==1) {
            setEditingRow(rowData?.IDBrandProduct)
            
          }
        }}
      >
        <div className='flex justify-content-center align-items-center gap-2'>
          <span> {editingStatus}</span>
          {
            (localStorage.getItem('Role')==1) &&
          <i className='pi pi-angle-down'></i>
          }
        </div>
      </Tag>
    );
  };


  const getSeverity = (status) => {
    switch (status) {
      case 'INACTIVE':
        return 'danger';

      case 'ACTIVE':
        return 'success';

      case 'PENDING':
        return 'warning';
      case '':
        return 'info';
    }
  };

  // handel image in tabel
  const imageBodyTemplate = (rowData) => {
    return <div className='flex justify-content-center'>
      <Image
        preview
        loading="lazy"
        src={rowData.BrandProductLogo}
        alt={'brnad iamge'}
        width='90'
        height='100%'
        className=' mx-auto overflow-hidden shadow-3  border-round' style={{ objectFit: 'cover' }}
      />
    </div>
  };

  const homeBodyTemplate = (rowData) => (
    <ToggleButton
      size='small'
      className={`w-2rem ${rowData.HomeBrandProduct === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
      checked={rowData.HomeBrandProduct === 1}
      onChange={() => handleToggle(rowData)}
    />
  );
  const [checkedItems, setCheckedItems] = useState({});
  const handleToggle = async (rowData) => {
    const updatedCheckedItems = { ...checkedItems, [rowData.id]: !checkedItems[rowData.id] };
    setCheckedItems(updatedCheckedItems);
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/home/${rowData?.IDBrandProduct}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {
      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
    })
  };

  const homeBodyTemplatePopular = (rowData) => (
    <ToggleButton
      size='small'
      className={`w-2rem ${rowData.BrandProductPopular === 1 ? 'bg-green-500 border-none p-1 text-sm' : 'bg-red-500 border-none p-1 text-0  text-sm'}`}
      checked={rowData.BrandProductPopular === 1}
      onChange={() => handleTogglePopular(rowData)}
    />
  );
  const [checkedItemsPopular, setCheckedItemsPopular] = useState({});
  const handleTogglePopular = async (rowData) => {
    const updatedCheckedItems = { ...checkedItemsPopular, [rowData.id]: !checkedItemsPopular[rowData.id] };
    setCheckedItemsPopular(updatedCheckedItems);
    await axios.get(`${process.env.REACT_APP_API_URL}/brands/products/popular/${rowData?.IDBrandProduct}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
      },
    }).then(({ data }) => {
      if (data?.Success) {
        toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
        fetchData()
      }
    }).catch((error) => {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });
    })
  };

  return (
    <div  >
      <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
      <div className="card">

        <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>

        <DataTable className="custom-datatable" scrollable scrollHeight="650px"
          ref={dt}
          value={Data}
          dataKey="id"
          globalFilter={globalFilter}
          rowHover
          showGridlines
          header={header}>
          <Column field="BrandProductSerialCode" header={t('BrandProductSerialCode')} style={{ minWidth: '10rem', textAlign: 'center' }} ></Column>
          <Column field="BrandProductLogo" header={t('image')} body={imageBodyTemplate}></Column>
          <Column field="BrandProductTitle" header={t('name')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {/* <Column field="BrandProductDesc" header={t('desc')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column> */}
          <Column field="CategoryName" header={t('categories_title')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          <Column field="SubCategoryName" header={isLang === "en" ? 'Sub Category' : 'تصنيف فرعي'} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {/* <Column field="CategoryType" body={text} header={t('type')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column> */}
          <Column field="BrandName" header={t('brand')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          {
            localStorage.getItem('Role') == 1 &&
            <Column field="SupplierName" header={t('SupplierName')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }
          <Column field="BrandProductPrice" header={t('price')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductOfferPrice" header={t('discount')} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          {
            localStorage.getItem('Role') == 1 &&
            <Column field="HomeBrandProduct" header={t('home')} body={homeBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          }
          {
            localStorage.getItem('Role') == 1 &&
            <Column field="BrandProductPopular" header={t('ProductFeatured')} body={homeBodyTemplatePopular} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
          }
          <Column field="BrandProductRating" header={t('Rating')} style={{ minWidth: '7rem', textAlign: 'center' }}></Column>
          <Column field="BrandProductStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>

        </DataTable>
        <Paginator dir='ltr'
          first={page * 10}
          rows={10}
          totalRecords={pagesNum * 10}
          onPageChange={(e) => setPage(e.page)}
          template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
        />
      </div>
      <BrandRating
        modalDone={modalRating}
        setModalDone={setModalRating}
        dataSocialMedia={dataSocialMedia}
      />
    </div>
  )
}


export default Products
