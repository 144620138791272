import axios from 'axios';
import { FetchApi } from 'context/FetchApi';
import { LocalizationContext } from 'context/LangChange';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useState } from 'react'

const ChangeSupplier = ({ toast, value, t, open,setValue, setOpenModal, IDOrderDetail, fetchData }) => {
    let { isLang } = useContext(LocalizationContext);
    const [loading, setLoading] = useState(false);
    let { productsAjex } = useContext(FetchApi)

    const hideDeleteusersDialog = () => setOpenModal(false);
    const changeApi = async () => {
        setLoading(true)
        const url = `${process.env.REACT_APP_API_URL}/orders/details/supplier/change`;
        let { data } = await axios.post(url, { IDOrderDetail, IDBrandProduct: value },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        if (data?.Success) {
            fetchData()
            toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
            setLoading(false)
            setOpenModal(false)
        }else{
            setLoading(false)

        }
    }
    const openFooter = (
        <div className='btn_gapAr flex justify-content-center align-items-center'>
            <Button size='small' label={isLang == "en" ? 'No' : 'لا'} icon="pi pi-times" severity="danger" outlined onClick={hideDeleteusersDialog} />
            <div className="btn_submit">
                <Button size='small' label={isLang == "en" ? 'Yes' : 'نــعم'} loading={loading} raised icon="pi pi-check" severity="danger" onClick={changeApi} />
            </div>
        </div>
    );

    return (
        <div>
            <Dialog
                dir={isLang == "en" ? 'ltr' : 'rtl'}
                modal className="p-fluid"
                visible={open}
                style={{ width: '32rem' }}
                breakpoints={{ '960px': '75vw', '641px': '90vw' }}
                header={t('changeSuppliers')}
                footer={openFooter}
                onHide={hideDeleteusersDialog}>
                <div className="lg:col-12 md:col-12 sm:col-12 mt-2     ">
                    <label htmlFor="IDBrand" className="font-bold block mb-2"> {t('products')}</label>
 
                    <Dropdown filter
                        options={productsAjex?.map(item => ({
                            name: `${item.BrandProductTitle + ' , ' + item.SupplierName + ' , ' + item.BrandProductPrice}`,
                            value: item.IDBrandProduct,
                        }))}
                        id="IDBrandProduct"
                        name="IDBrandProduct"
                        optionLabel="name"
                        optionValue="value"
                        value={value}
                        onChange={(e) => {
                            setValue(e.value)
                        }}
                        placeholder={t('Products_placeholder')}
                        className="w-full p-inputtext-sm"
                       
                    />
                </div>

            </Dialog>
        </div>
    )
}

export default ChangeSupplier