
import axios from 'axios';
import Component from 'constants/Component';
import LogoSvg from 'constants/LogoSvg';
import { LocalizationContext } from 'context/LangChange';
import { format } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const PromoCodeList = () => {
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const dt = useRef(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [Data, setData] = useState(null);
    const [page, setPage] = useState(0);
    const [pagesNum, setPagesNum] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedType, setSelectedType] = useState('');
    const [modalDone, setModalDone] = useState(false);
    const [IDProduct, setIDProduct] = useState(null);

    const statusOptions = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('DEACTIVATED'), value: 'DEACTIVATED' },
    ];
    const statusOptionsFiter = [
        { label: t('active'), value: 'ACTIVE' },
        { label: t('DEACTIVATED'), value: 'DEACTIVATED' },
        { label: t('expired'), value: 'EXPIRED' },
        { label: t('pending'), value: 'PENDING' },
    ];
    const typeOptionsFiter = [
        { label: t('value'), value: 'VALUE' },
        { label: t('PERCENT'), value: 'PERCENT' },
    ];

    const handelPromoCodesProduct = (data) => {
        setModalDone(true)
        setIDProduct(data?.IDPromoCode);
    }

    // Add new Tools
    const leftToolbarTemplate = () => {
        return (
            <div className="btn_gapAr flex flex-wrap gap-2 p-button-main">
                <Link to="/promo/add">
                    <Button severity="danger" label={t('add')} icon="pi pi-plus" size='small' />
                </Link>
                <Button /* label={t('reset')} */ onClick={() => resetData()} text raised severity="danger" icon="pi pi-history" size='small' />
            </div>
        );
    };
    const resetData = () => {
        fetchData();
        setSelectedStatus('');
        setSelectedType('');
    }

    // handel btn action in tabel 
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="flex gap-2 justify-content-center align-content-center">
                <Link to={`/promo/edit/${rowData.IDPromoCode}`}>
                    <Button icon="pi pi-pencil" rounded outlined raised text className="mr-2" tooltip={t('update')} tooltipOptions={{ position: t('tooltip') }} />
                </Link>
                <Button onClick={(e) => handelPromoCodesProduct(rowData)} icon={<LogoSvg.Prodact className="logoSvg" style={{ width: 17 }} />} rounded raised severity='danger' className="mr-2" tooltip={t('products')} tooltipOptions={{ position: t('tooltip') }} />
                <Tooltip target=".disabled-button" autoHide={false} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <div className='flex gap-4  w-5'>
                <Dropdown
                    options={statusOptionsFiter}
                    value={selectedStatus}
                    onChange={e => {
                        fetchData(e.value)
                        setSelectedType('');
                    }}
                    placeholder={selectedStatus ? statusOptionsFiter.find(option => option.value === selectedStatus).label : "Select Status "}
                    className="p-inputtext-sm w-full"
                />
                <Dropdown
                    options={typeOptionsFiter}
                    value={selectedType}
                    onChange={e => {
                        fetchData(false, e.value)
                        setSelectedStatus('');
                    }}
                    placeholder={selectedType ? typeOptionsFiter.find(option => option.value === selectedType).label : "Select Type"}
                    className="p-inputtext-sm w-10"
                />

            </div>
            <span className="p-input-icon-left">
                {/* <i className="pi pi-search" />
                <InputText type="search" className="w-full p-inputtext-sm" onInput={(e) => setGlobalFilter(e.target.value)} placeholder={t('search_placeholder')} /> */}
            </span>

        </div>
    );

    const fetchProfileData = async () => {
        const url = `${process.env.REACT_APP_API_URL}/promos`;
        let data = await axios.post(url, { IDPage: page + 1 },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.PromoCodes);
        setPagesNum(data?.data?.Response?.Pages)
    }
    useEffect(() => {
        fetchProfileData();
    }, [isLang, page])

    // Filter
    const fetchData = async (PromoCodeStatus, PromoCodeType) => {
        let body = {
            IDPage: page + 1,
        }
        if (PromoCodeStatus) {
            setSelectedStatus(PromoCodeStatus);
            body.PromoCodeStatus = PromoCodeStatus
        }
        if (PromoCodeType) {
            setSelectedType(PromoCodeType);
            body.PromoCodeType = PromoCodeType
        }

        const url = `${process.env.REACT_APP_API_URL}/promos`;
        let data = await axios.post(url, body,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
                },
            })
        setData(data?.data?.Response?.PromoCodes);
        setPagesNum(data?.data?.Response?.Pages)
    }


    const [editingRow, setEditingRow] = useState(null);

    const statusBodyTemplate = (rowData) => {
        const editingStatus = rowData?.PromoCodeStatus === 'ACTIVE' ? t('active') : rowData?.PromoCodeStatus === 'DEACTIVATED' ? t('DEACTIVATED') : rowData?.PromoCodeStatus === 'EXPIRED' ? t('EXPIRED') : t('pending')
        return editingRow === rowData?.IDPromoCode ? (
            <Dropdown
                value={editingStatus}
                options={statusOptions.filter((option) => option.value !== rowData?.PromoCodeStatus)}
                onChange={(e) => onStatusChange(rowData, e)}
                placeholder={`${editingStatus}`}
                className="p-inputtext-sm "
            />
        ) : (
            <Tag rounded
                severity={getSeverity(rowData.PromoCodeStatus)}
                onClick={() => {
                    if (rowData?.PromoCodeStatus === 'EXPIRED') {
                        setEditingRow(rowData?.IDPromoCode)
                        
                    }
                }}
            >
                <div className='flex justify-content-center align-items-center gap-2'>
                    <span> {editingStatus}</span>
                    <i className='pi pi-angle-down'></i>
                </div>
            </Tag>
        );
    };
    const onStatusChange = async (rowData, e) => {
        setEditingRow(null);
        await axios.post(`${process.env.REACT_APP_API_URL}/promos/status`, { IDPromoCode: rowData?.IDPromoCode, PromoCodeStatus: e?.value }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
        }).then(({ data }) => {

            if (data?.Success) {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: data?.ApiMsg, life: 3000 });
                fetchData()
            }
        }).catch((error) => {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Data invalid', life: 3000 });

        })
    };
    const getSeverity = (status) => {
        switch (status) {
            case 'EXPIRED':
                return 'danger';
            case 'DEACTIVATED':
                return 'danger'; 
            case 'ACTIVE':
                return 'success'; 
            case 'PENDING':
                return 'warning';
            case '':
                return 'info';
        }
    };


    // Handel date view format
    const date = (rowData) => {
        const formattedDate = format(new Date(rowData?.created_at), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const startdate = (rowData) => {
        const formattedDate = format(new Date(rowData?.PromoCodeStartDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const enddate = (rowData) => {
        const formattedDate = format(new Date(rowData?.PromoCodeEndDate), 'dd-MM-yyyy');
        return <span>{formattedDate}</span>;
    };
    const handeText = (text) => {
        return text?.PromoCodeType.replace(/_/g, ' ').charAt(0).toUpperCase() + text?.PromoCodeType.replace(/_/g, ' ').slice(1)?.toLowerCase()
    }
    const priceType = (rowData) => {
        if (rowData?.PromoCodeType === "PERCENT") {
            return <span>{rowData?.PromoCodeValue}%</span>
        } else {
            return <span>{rowData?.PromoCodeValue}</span>

        }
    };
    return (
        <div className='mb-8'>
            <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
            <div className="card">

                <Toolbar className="mb-4" left={leftToolbarTemplate}  ></Toolbar>


                <DataTable className="custom-datatable" scrollable scrollHeight="550px"
                    ref={dt}
                    value={Data}
                    dataKey="id"
                    globalFilter={globalFilter}
                    rows={10}
                    size='small'
                    showGridlines
                    header={header}>
                    <Column field="PromoCode" header={t('PromoCodes')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeType" body={handeText} header={t('type')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeTotalAmount" header={t('MinimimOrder')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeValue" body={priceType} header={t('value')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeValueMax" header={t('ValueMax')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeMaxNumber" header={t('MaxNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeUseNumber" header={t('UseNumber')} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeStartDate" header={t('start_date')} body={startdate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeEndDate" header={t('end_date')} body={enddate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="created_at" header={t('created_at')} body={date} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                    <Column field="PromoCodeStatus" header={t('status')} body={statusBodyTemplate} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>

                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '10rem' }}></Column>
                </DataTable>

                <Paginator dir='ltr'
                    first={page * 10}
                    rows={10}
                    totalRecords={pagesNum * 10}
                    onPageChange={(e) => setPage(e.page)}
                    template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink' }}
                />
            </div>
            <Component.PromoCodeProducts
                modalDone={modalDone}
                setModalDone={setModalDone}
                id={IDProduct}
            />
        </div>
    )
}


export default PromoCodeList