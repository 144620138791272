import axios from 'axios';
import Img from 'constants/Img';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateLoginForm } from '../Services/Validation';
import "./login.scss";
import { LocalizationContext } from 'context/LangChange';

const Login = () => {
    const toast = useRef(null);
    let { isLang } = useContext(LocalizationContext)
    const [loademail, setLoadEmail] = useState(false);
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    const [loginByPhone, setLoginByPhone] = useState(1);


    let navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            UserName: '',
            Password: ''
        },
        validationSchema: validateLoginForm,
        onSubmit: async (values) => {

            setLoadEmail(true)
            if (loginByPhone === 1) {
                let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, values);
                if (data.Success === true) {
                    localStorage.setItem("tokenBetter", data.Response.AccessToken);
                    localStorage.setItem("IDUser", data.Response.IDUser);
                    localStorage.setItem("IDBrand", data.Response.IDBrand);
                    localStorage.setItem("UserName", data.Response.UserName);
                    localStorage.setItem("UserEmail", data.Response.UserEmail);
                    localStorage.setItem("UserPhone", data.Response.UserPhone);
                    localStorage.setItem("UserLanguage", data.Response.UserLanguage);
                    localStorage.setItem("IDBrand", data.Response.IDBrand);
                    localStorage.setItem("UserStatus", data.Response.UserStatus);
                    localStorage.setItem("Role", data.Response.IDRole);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoadEmail(false)
                        navigate('/');
                        window.location.reload(); // Reload the page

                    }, 1500);

                } else {
                    toast.current.show({ severity: 'error', summary: 'error', detail: data?.ApiMsg, life: 3000 });

                    setTimeout(() => {
                        setLoadEmail(false)
                    }, 1500);
                }
            } else {
                let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
                    UserName: '+2' + values?.UserName,
                    Password: values?.Password
                });
                if (data.Success === true) {
                    localStorage.setItem("tokenBetter", data.Response.AccessToken);
                    localStorage.setItem("IDUser", data.Response.IDUser);
                    localStorage.setItem("IDBrand", data.Response.IDBrand);
                    localStorage.setItem("UserName", data.Response.UserName);
                    localStorage.setItem("UserEmail", data.Response.UserEmail);
                    localStorage.setItem("UserPhone", data.Response.UserPhone);
                    localStorage.setItem("UserLanguage", data.Response.UserLanguage);
                    localStorage.setItem("IDBrand", data.Response.IDBrand);
                    localStorage.setItem("UserStatus", data.Response.UserStatus);
                    localStorage.setItem("Role", data.Response.IDRole);
                    toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
                    setTimeout(() => {
                        setLoadEmail(false)
                        navigate('/');
                        window.location.reload(); // Reload the page

                    }, 1500);

                } else {
                    toast.current.show({ severity: 'error', summary: 'error', detail: data?.ApiMsg, life: 3000 });

                    setTimeout(() => {
                        setLoadEmail(false)
                    }, 1500);
                }
            }
        }
    });
    const [isValidPass, setIsValidPass] = useState(false);
    useEffect(() => {
        const isValidFormpass = Object.values(formik.errors).every((val) => !val);
        setIsValidPass(isValidFormpass);
    }, [formik.errors]);

    return (
        <>
            <Toast ref={toast} position='top-right' />
            <div className="app__login    flex justify-content-center align-items-center">
                <div className="app__login-left bg__login shadow-2">
                    <img loading="lazy" src={Img.loginBg} width={'30px'} height={'20px'} className='bg__login-icons' alt="Login page background" />
                    <div className="login__form_inputs">
                        <form onSubmit={formik.handleSubmit} className='login__form'>
                            <div className="input_form">
                                {/*             {
                                    loginByPhone === 1 ?
                                        <label htmlFor="UserName" >User Name</label> :
                                        <label htmlFor="UserName" >Mobile number</label>
                                } */}
                                <span className=" p-input-icon-left  mt-5">
                                    <i className="pi pi-user" />
                                    <InputText
                                        id="UserName"
                                        name="UserName"
                                        type="text"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder={loginByPhone === 1 ? "enter your name" : "enter your phone"}
                                        className={` custom-input w-full`} // Add the custom-input class here
                                        dir='ltr'
                                        required
                                    />

                                </span>
                         {/*        {formik.errors.UserName && formik.touched.UserName ?
                                    <span className='error_handel'>
                                        {formik.errors.UserName}
                                    </span>
                                    : null
                                } */}

                            </div>
                            <div className="input_form">
                                {/* <label htmlFor="Password">Password</label> */}
                                <div className="password_open p-input-icon-left w-full  ">
                                    <i className="pi pi-lock" />
                                    <InputText
                                        id="Password"
                                        name="Password"
                                        type={handelOpenPassword}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        placeholder={'enter your password'}
                                        className='w-full'
                                        dir='ltr'
                                        required
                                    />
                                    {
                                        handelOpenPassword === "password" ?
                                            <i className="pi pi-eye-slash app__login-btn-icons" onClick={() => setHandelOpenPassword('text')}  ></i> :
                                            <i className="pi pi-eye app__login-btn-icons" onClick={() => setHandelOpenPassword('password')}  ></i>
                                    }
                                </div>


                    {/*             {formik.errors.Password && formik.touched ?
                                    <span className='error_handel'>
                                        {formik.errors.Password}
                                    </span> : null
                                } */}
                            </div>
                            <div className='w-100 mt-4 d-flex align-items-center justify-content-center'>
                                <Button raised rounded loading={loademail} severity="danger" label='Login' className='w-full' type='submit' />
                            </div>
                            {/*                           {
                                loginByPhone === 1 ?
                                    <a onClick={() => setLoginByPhone(2)} className='  cursor-pointer flex justify-content-center align-items-center w-full'> {isLang === "en" ? 'Sign In by phone ?' : 'تسجبل دخول بالهاتف'}    </a> :
                                    <a onClick={() => setLoginByPhone(1)} className='  cursor-pointer flex justify-content-center align-items-center w-full'>  {isLang === "en" ? 'Sign In by  email ?' : 'تسجبل دخول بالبريد الإلكتروني'}</a>

                            } */}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;