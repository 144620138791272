import axios from 'axios';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
const AddProductAttributes = () => {
  let { id } = useParams()
  let { isLang } = useContext(LocalizationContext);
  let { t } = useTranslation()
  const url = `${process.env.REACT_APP_API_URL}/brands/products/attributes/add`;
  const [loading, setLoading] = useState(false);
  const toast = useRef(null);
  let navigate = useNavigate()
  const items = [
    { label: <Link className='opacity-70' to={'/products'}>{t('products')}   </Link> },
    { label: <Link className='opacity-70' to={`/products/attributes/${id}`}  >  {t('attributes')}  </Link> },
    { label: <Link to={`/products/attributes/${id}/add`} className='p-2 border-round'>  {t('add')}  </Link> }
  ];
  const home = { icon: 'pi pi-home', url: '/' };
  const DiscountType = [
    { name: t('PRODUCT'), value: 'PRODUCT' },
    { name: t('SPECIFICATION'), value: 'SPECIFICATION' },
  ];
  const formik = useFormik({
    initialValues: {
      ProductAttributeEn: '',
      ProductAttributeAr: '',
      ProductAttributeType: 'PRODUCT',
      IDBrandProduct: id
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        let { data } = await axios.post(url, values,
          {
            headers: {
              // 'Content-Type': 'application/json',
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer ' + localStorage.getItem('tokenBetter'),
            },
          })

        if (data?.Success) {
          toast.current.show({ severity: 'success', summary: 'Success', detail: data?.ApiMsg, life: 3000 });
          setTimeout(() => {
            setLoading(false);
            navigate(`/products/attributes/${id}`)
            resetForm();
          }, 1000);
        } else if (!data?.Success) {
          toast.current.show({ severity: 'info', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });
          setLoading(false);
        } else if (data?.status === 400) {
          toast.current.show({ severity: 'warn', summary: 'Warn', detail: data?.ApiMsg, life: 3000 });

          setLoading(false);
        } else {
          toast.current.show({ severity: 'error', summary: 'Error', detail: data?.ApiMsg, life: 3000 });
        }

      } catch ({ response }) {
        console.error(response.data.message);
        toast.current.show({ severity: 'error', summary: 'Error', detail: response.data.message, life: 3000 });
        setLoading(false);
      }
    }
  });

  return (
    <div>
      <div className='mb-8'>
        <Toast ref={toast} position={isLang === "en" ? 'top-right' : 'top-left'} />
        <BreadCrumb model={items} home={home} />
        <form onSubmit={formik.handleSubmit} className='mt-6 w-11  m-auto'>
          <div className="grid  mt-5  ">
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="ProductAttributeEn" className="font-bold block mb-2">{t('label_categories_nameEn')}</label>
              <InputText
                name='ProductAttributeEn'
                id="ProductAttributeEn"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12   mt-2 input__Col" >
              <label htmlFor="ProductAttributeAr" className="font-bold block mb-2">{t('label_categories_nameAr')}</label>
              <InputText
                name='ProductAttributeAr'
                id="ProductAttributeAr"
                type='text'
                className="w-full  p-inputtext-sm"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>
            <div className="lg:col-6 md:col-12 sm:col-12 mt-2 input__Col ">
              <label htmlFor="ProductAttributeType" className="font-bold block mb-2"> {t('type')} </label>
              <Dropdown  
                id="ProductAttributeType"
                name="ProductAttributeType"
                value={formik.values.ProductAttributeType}
                optionLabel="name"
                onChange={(e) => formik.setFieldValue("ProductAttributeType", e.value)}
                onBlur={formik.handleBlur}
                options={DiscountType}
                placeholder={t('Discounttype')}
                className="w-full p-inputtext-sm"


              />

            </div>
          </div>
          <div className="btn_gapAr group flex justify-content-center gap-4 mt-4 ">
            <div className="btn_submit">
              <Button severity="danger" raised icon="pi pi-check" loading={loading} label={t('submit')} type="submit" size='small' className='mt-3' />
            </div>
            <Link to={`/products/attributes/${id}`}>
              <Button label={t('cancel')} severity="danger" type="reset" outlined size='small' className='mt-3' />
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}


export default AddProductAttributes