import { LocalizationContext } from 'context/LangChange'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

const OrderAddress = ({ address }) => {
    let { t } = useTranslation()
    const mainAddress = (item) => {
        console.log(item);
        if (item?.MainAddress == 1) {
            return <i className='pi pi-check-circle  '></i>
        } else {
            return <i className='pi pi-times-circle  '></i>
        }
    }
    const location = (item) => {
        return <a href={`https://www.google.com/maps/?q=${item?.AddressLatitude},${item?.AddressLongitude}`} target="_blank" rel="noopener noreferrer"> {t('openMap')}      </a>
    }
    const mainAddress1 = (item) => {
        console.log(item);
        return <span>{item?.CountryName + ' , ' + item?.CityName + ' , ' + item?.AreaName + ' , ' + item?.ClientAddress}</span>
    }
    return (
        <div>
            <div className="info_contactus mt-4">
                <div className="conatent d-flex   align-items-center ">
                    <div className="icons">
                        <i className='pi pi-map-marker'></i>
                    </div>
                    <div>
                        <div className="header_gallery_Tool"> <span className='font-bold fs-1 m-0 p-0'>{t('addres')}</span>   </div>
                    </div>
                </div>
                <div className="card mb-4">
                    <DataTable
                        className='custom-datatable'
                        scrollable
                        scrollHeight='550px'
                        value={address}
                        dataKey='id'
                        showGridlines
                    >
                        <Column field="MainAddress" header={t('addres')} body={mainAddress1} style={{ minWidth: '10rem', textAlign: 'center' }}></Column>
                        <Column field="AddressName" header={t('name')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="AddressPhone" header={t('phone')} style={{ minWidth: '12rem', textAlign: 'center' }}></Column>
                        <Column field="MainAddress" header={t('main')} body={mainAddress} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                        <Column field="MainAddress" header={t('location')} body={location} style={{ minWidth: '8rem', textAlign: 'center' }}></Column>
                    </DataTable>

                </div>
            </div>
            <div className="pt-5 pb-5">
                <hr />
            </div>
        </div>
    )
}

export default OrderAddress